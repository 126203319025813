import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/Inputs/inputText';
import InputTextArea from 'components/Inputs/inputTextArea';
import InputSelect from 'components/Inputs/inputSelect';
import Button from 'components/Buttons/Button';
import InputNumber from 'components/Inputs/inputNumber';
import KpiRelationDetails from 'routes/Performance/components/KpiRelationDetails';
import Popup from 'components/Popup';
import { ChevronLeft, Send } from 'lucide-react';
import {
  KPI_AGGREGATION, KPI_CATEGORY, KPI_PERIODICITY, KPI_UNIT, KPI_TREND,
} from 'constants/kpis';
import useDialog from 'components/Dialog/components/useDialog';
import { PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { TOOL_PERFORMANCE } from 'constants/tools';
import { initPermission } from 'utils/functions/initPermissions';
import InputPermission from 'components/Inputs/InputPermission';

const emptyForm = {
  name: {},
  description: {},
  category: '',
  unit: '',
  positive: '',
  periodicity: '',
  aggregate: '',
  min: '',
  max: '',
  permission: null,
};

const PerformanceAddTabCustom = (props) => {
  const {
    match,
    literals,
    literalsCommon,
    startup,
    kpi = null,
    onSubmit,
    lang,
    onClose = false,
  } = props;

  const [formData, setFormData] = useState({
    ...(kpi || emptyForm),
    permission: initPermission(kpi?.id ? kpi : null, PERMISSION_SCOPES.STARTUP, startup, TOOL_PERFORMANCE),
  });

  const {
    name,
    description,
    category,
    unit,
    positive,
    periodicity,
    aggregate,
    min,
    max,
    permission,
  } = formData;

  const { canEdit } = permission;

  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detailKpiRelation, setDetailKpiRelation] = useState(false);
  const { dialog } = useDialog();

  const fieldsValid = name && description && category && unit && positive && periodicity;

  const categories = [
    { id: KPI_CATEGORY.BUSINESS, name: literals.business },
    { id: KPI_CATEGORY.FINANCIAL, name: literals.financial },
    { id: KPI_CATEGORY.ESG, name: literals.esg },
  ];
  const units = [
    { id: KPI_UNIT.INTEGER, name: literals.integer },
    { id: KPI_UNIT.FLOAT, name: literals.float },
    { id: KPI_UNIT.CURRENCY, name: literalsCommon.currency },
    { id: KPI_UNIT.PERCENT, name: literals.percent },
  ];
  const trends = [
    { id: KPI_TREND.UP, name: literals.up },
    { id: KPI_TREND.DOWN, name: literals.down },
  ];
  const periodicities = [
    { id: KPI_PERIODICITY.MONTHLY, name: literals.monthly },
    { id: KPI_PERIODICITY.QUARTERLY, name: literals.quarterly },
    { id: KPI_PERIODICITY.ANNUAL, name: literals.annual },
  ];
  const aggregates = [
    { id: KPI_AGGREGATION.EOP, name: literals.endOfPeriod },
    { id: KPI_AGGREGATION.ACC, name: literals.accumulative },
    { id: KPI_AGGREGATION.AVG, name: literals.average },
  ];

  const setFormValue = (field, value) => {
    setFormData(v => ({ ...v, [field]: value }));
  };

  const submitForm = async (ev, save) => {
    ev.preventDefault();
    const params = {
      name,
      description,
      category,
      unit,
      positive,
      periodicity,
      aggregate,
      scope: 'startup',
      owner: match.params.id,
      permission,
      save,
    };

    if (min !== null && min !== '') {
      params.min = parseFloat(min);
    }
    if (max !== null && max !== '') {
      params.max = parseFloat(max);
    }

    setLoading(true);
    const kpiRelation = kpi?.id ? await onSubmit(kpi.id, params) : await onSubmit(params);
    setLoading(false);
    if (kpiRelation) {
      if (kpiRelation.errors) {
        setErrors(kpiRelation.errors);
      } else if (onClose) {
        onClose();
      } else {
        setFormData({
          ...emptyForm,
          permission: initPermission(null, PERMISSION_SCOPES.STARTUP, startup, TOOL_PERFORMANCE),
        });
        setDetailKpiRelation(kpiRelation);
      }
    } else {
      await dialog({
        type: 'error',
        text: literalsCommon.genericError,
      });
    }
  };

  if (errors.length) {
    const auxErrors = {};
    errors.forEach((error) => {
      if (!auxErrors[error.error]) {
        auxErrors[error.error] = [];
      }
      auxErrors[error.error].push(error.date);
    });

    return (
      <>
        <h6>{literals.ajustValues}</h6>
        <ul className='ml-4'>
          {Object.keys(auxErrors).map(key => (
            <li className='mb-1' title={auxErrors[key].join(', ')}>
              <span className='fw-b'>{auxErrors[key].length}</span>
              <span>
                {` ${auxErrors[key].length > 1 ? literals.values.toLowerCase() : literals.value.toLowerCase()} 
                  ${literals.errors[key]}`}
              </span>
            </li>
          ))}
        </ul>
        <div className='buttons'>
          <Button
            color='secondary'
            icon={ChevronLeft}
            text={literals.back}
            onClick={() => setErrors([])}
            loading={loading}
          />
          <Button
            onClick={e => submitForm(e, true)}
            icon={Send}
            text={literalsCommon.confirm}
            loading={loading}
            disabled={Object.keys(formData).some(key => (
              !['min', 'max'].includes(key) && !formData[key]
            ))}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <form className='add-kpi-form' onSubmit={e => submitForm(e, false)}>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <InputSelect
              preText={literals.category}
              options={categories}
              placeholder={literals.category}
              onChange={v => setFormValue('category', v)}
              value={category}
              isDisabled={!canEdit}
              isRequired
            />
            <InputText
              preText={literalsCommon.name}
              placeholder={literalsCommon.name}
              onChange={v => setFormValue('name', { [lang]: v })}
              value={name[lang]}
              isDisabled={!canEdit}
              isRequired
            />
            <InputSelect
              preText={literals.unit}
              options={units}
              placeholder={literals.unit}
              onChange={v => setFormValue('unit', v)}
              value={unit}
              isDisabled={!canEdit}
              isRequired
            />
            <InputTextArea
              preText={literalsCommon.description}
              placeholder={literalsCommon.description}
              onChange={v => setFormValue('description', { [lang]: v })}
              value={description[lang]}
              minHeight={150}
              isDisabled={!canEdit}
              isRequired
            />
          </div>
          <div className='col-12 col-md-6'>
            <InputPermission
              scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
              element={{ type: PERMISSION_TYPES.KPI, id: null }}
              value={permission}
              onChange={v => setFormValue('permission', v)}
            />
            <InputSelect
              preText={literals.periodicity}
              options={periodicities}
              placeholder={literals.periodicity}
              onChange={v => setFormValue('periodicity', v)}
              value={periodicity}
              isDisabled={!canEdit}
              isRequired
            />
            <InputSelect
              preText={literals.aggregate}
              options={aggregates}
              placeholder={literals.aggregate}
              onChange={v => setFormValue('aggregate', v)}
              value={aggregate}
              isDisabled={!canEdit}
              isRequired
            />
            <InputSelect
              preText={literals.positive}
              options={trends}
              placeholder={literals.positive}
              onChange={v => setFormValue('positive', v)}
              value={positive}
              isDisabled={!canEdit}
              isRequired
            />
            <div className='row'>
              <div className='col-6'>
                <InputNumber
                  preText={literals.min}
                  value={min}
                  onChange={v => setFormValue('min', v)}
                  placeholder={literals.infiniteNegative}
                  error={errors?.min}
                  isDisabled={!canEdit}
                />
              </div>
              <div className='col-6'>
                <InputNumber
                  preText={literals.max}
                  value={max}
                  onChange={v => setFormValue('max', v)}
                  placeholder={literals.infinitePositive}
                  error={errors?.max}
                  isDisabled={!canEdit}
                />
              </div>
            </div>
          </div>
        </div>
        {canEdit && (
          <div className='buttons'>
            <Button type='submit' text={literalsCommon.send} loading={loading} disabled={!fieldsValid} />
          </div>
        )}
      </form>
      {
        detailKpiRelation && (
          <Popup title={detailKpiRelation.kpi?.category} size='large' onClose={() => setDetailKpiRelation(false)}>
            <KpiRelationDetails startup={startup} relation={detailKpiRelation} onClose={() => setDetailKpiRelation(false)} />
          </Popup>
        )
      }
    </>
  );
};

PerformanceAddTabCustom.propTypes = {
  match: PropTypes.object.isRequired,
  literals: PropTypes.object.isRequired,
  literalsCommon: PropTypes.object.isRequired,
  startup: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  kpi: PropTypes.object,
  onClose: PropTypes.func,
};

export default PerformanceAddTabCustom;
