export default {
  it: {
    login: {
      title: 'Accedi al tuo account',
      email: 'Email',
      password: 'Password',
      login: 'Accedi',
      noAccount: 'Non hai un account?',
      questionNotRegistered: 'Non hai un account?',
      createAccount: 'Crea un nuovo account',
      register: 'Se non hai ancora un account, puoi crearne uno qui',
      invalidLogin: 'Nome utente o password non validi',
      invalidToken: 'Token non valido',
      recoveryButton: 'Hai dimenticato la password?',
      recovery: {
        title: 'Recupera la tua password',
        description: 'Ti invieremo un link via email per reimpostare la tua password',
        button: 'Invia',
        successMessage: "Se l'email esiste, ti invieremo un messaggio con le istruzioni per impostare la tua nuova password",
        back: 'Torna al login',
      },
      email_error: "L'email non è nel formato corretto",
      token: 'Token',
      tokenMsg: 'Inserisci il codice che ti abbiamo inviato via email',
    },
    legal: {
      home: 'Home',
    },
    tool: {
      startup: 'Avvia',
      mentor: 'Mentore',
      mentorings: 'Mentorati',
    },
    setPassword: {
      title: 'Imposta password',
      description: 'Imposta di seguito la tua password di accesso, deve essere lunga almeno 10 caratteri e includere maiuscole e minuscole',
      password: 'Password',
      repeatPassword: 'Ripeti la password',
      register: 'Conferma',
      passwordRules: 'La password deve contenere almeno 10 caratteri e almeno una maiuscola e una minuscola',
      passwordEqualError: 'Non è possibile utilizzare password precedenti',
      nonRepeatedPassword: 'Le password inserite non corrispondono',
      invalidToken: 'Il link non è valido, richiedine uno nuovo',
      passwordSuccess: 'La tua password è stata impostata correttamente, effettua il login qui sotto per accedere alla piattaforma',
    },
    signup: {
      title: 'Registrati',
      description: 'Prossimamente',
      firstname: 'Nome',
      lastname: 'Cognome',
      email: 'Email',
      language: 'Lingua',
      role: 'Ruolo',
      signup: 'Registrati',
      createAccount: 'Crea un account',
      incorrectEmail: "L'email inserita non è valida",
      haveAnAccount: 'Ho già un account',
      doLogin: 'Accedi con esso',
      questionRegistered: 'Hai già un account?',
      es: 'Spagnolo',
      en: 'Inglese',
      it: 'Italiano',
      mentor: 'Mentore',
      user: 'Utente',
      notFound: 'La registrazione non è abilitata',
      goToLogin: 'Vai al login',
      signupSuccess: "Account creato con successo, riceverai presto un'email con le istruzioni per l'accesso",
      signupError: 'Controlla i tuoi dati e assicurati di non avere già un account creato',
      socialSignupError: 'Verifica di aver dato il permesso per conoscere il tuo indirizzo email e riprova',
      or: 'o',
    },
    downloadFile: {
      unauthenticated: 'Accedi per scaricare il file',
      noPermissions: 'Non hai i permessi per scaricare il file',
      noExists: 'Il file non esiste',
      login: 'Accedi',
      downloadingFile: 'Download del file in corso',
      downloadedFile: 'File scaricato',
      genericError: "Si è verificato un errore, conferma che l'URL sia corretto e riprova",
    },
    topBar: {
      account: 'Account / Profilo',
      settings: 'Impostazioni',
      logout: 'Esci',
      loading: 'Caricamento',
      noNotifications: 'Non hai notifiche',
      markAsRead: 'Segna come letta',
      markAsUnread: 'Segna come non letta',
      delete: 'Elimina',
      help: 'Aiuto',
      addCompany: 'AGGIUNGI AZIENDA',
      prehome: 'Home',
      pushNotifications: 'Notifiche push',
      viewMyPosition: 'Vedi la mia posizione',
      youAreImpersonating: 'Stai impersonando',
      notifications: {
        all: 'Tutte',
        unread: 'Non lette',
        mentoring: {
          mentoringInviteMentor: '%FROM_NAME% ha richiesto di avviare una mentoring con te',
          mentoringInviteStartup: '%FROM_NAME% ha richiesto di avviare una mentoring con %STARTUP_NAME%',
          mentoringAccepted: '%FROM_NAME% ha accettato la richiesta di mentoring',
          mentoringRejected: '%FROM_NAME% ha rifiutato la richiesta di mentoring',
          mentoringFinished: '%FROM_NAME% ha concluso la mentoring',
          mentoringMessage: 'Nuovo messaggio da %FROM_NAME% nella mentoring',
          mentoringMessages: '%COUNT% nuovi messaggi da %FROM_NAME% nella mentoring',
          mentoringMeeting: '%FROM_NAME% ha registrato un nuovo incontro',
          mentoringMeetingUpdate: '%FROM_NAME% ha aggiornato un incontro',
          mentoringMeetingDelete: '%FROM_NAME% ha cancellato un incontro',
        },
        startup: {
          invite: '%FROM_NAME% ti ha invitato a unirti a %STARTUP_NAME%',
          inviteAccepted: '%USER_NAME% ha accettato di unirsi a %STARTUP_NAME%',
          inviteRejected: '%USER_NAME% ha rifiutato di unirsi a %STARTUP_NAME%',
        },
        acceptPermissions: 'Accetta i permessi del browser',
        notificationsActivated: 'Notifiche attivate',
        notificationsActivatedMessage: 'Da ora in poi riceverai le notifiche nel browser',
        notificationsDeactivated: 'Notifiche disattivate',
        notificationsDeactivatedMessage: 'Da ora in poi smetterai di ricevere le notifiche nel browser',
      },
    },
    leftMenu: {
      summary: 'Home',
      myPosition: 'La mia posizione',
      capTable: 'Captable',
      fundraise: 'Raccolta fondi',
      funds: 'Fondi',
      performance: 'Prestazioni',
      mentoring: 'Mentoraggio',
      mentorings: 'Mentoraggi',
      reporting: 'Segnalazione',
      services: 'Servizi',
      dataRoom: 'Documenti',
      logs: 'Log',
      webhooks: 'Webhook',
      scripts: 'Script',
      crons: 'Compiti Cron',
      settings: 'Impostazioni',
      userAccess: 'Accesso utente',
      companies: 'Aziende',
      platform: 'Piattaforma',
      profile: 'Profilo',
      help: 'Aiuto',
      analytics: 'Analisi',
      emailLogs: 'Log email',
      profileStartup: 'Startup',
      capTableSummary: 'Riepilogo',
      capTableSecurities: 'Titoli',
      capTableOperations: 'Operazioni',
      capTableTransactions: 'Transazioni',
      capTableShareholders: 'Azionisti',
      capTableVestingPlans: 'Piani di vesting',
      capTableShareClasses: 'Classi di azioni',
      capTableWaterfall: 'Waterfall',
      addCompany: 'AGGIUNGI AZIENDA',
      portfolio: 'Portafoglio',
      analysis: 'Analisi',
      opportunities: 'Opportunità',
      permissions: 'Autorizzazioni',
    },
    dialog: {
      accept: 'Accetta',
      confirm: 'Conferma',
      cancel: 'Annulla',
    },
    permissions: {
      permissions: 'Autorizzazioni',
      addPersonOrGroup: 'Aggiungi persona o gruppo',
      addPerson: 'Aggiungi persona',
      addGroup: 'Aggiungi gruppo',
      inputPersonOrGroup: 'Inserisci il nome della persona o del gruppo',
      inputGroupName: 'Inserisci il nome del gruppo',
      members: 'Membri',
      usersWithAccess: 'Utenti con accesso',
      groupsWithAccess: 'Gruppi con accesso',
      noPermissionsToSeeMembers: 'Non hai le autorizzazioni per vedere i membri',
      group: 'Gruppo',
      groups: 'Gruppi',
      users: 'Utenti',
      manage: 'Gestisci',
      owner: 'Proprietario',
      removePermissions: 'Rimuovi autorizzazioni',
      createGroup: 'Crea gruppo',
      editGroup: 'Modifica gruppo',
      editUserPermissions: 'Modifica autorizzazioni utente',
      giveMorePermissions: 'Concedi più autorizzazioni',
      permissionPrevailsInfo: "Viene considerata l'autorizzazione di più alto livello tra quelle definite dai gruppi e quella dell'utente.",
      searchUsers: 'Cerca utenti',
      searchGroups: 'Cerca gruppi',
      searchUsersAndGroups: 'Cerca utenti e gruppi',
      noUsers: 'Non ci sono ancora utenti',
      noGroups: 'Non ci sono ancora gruppi',
      private: 'Privato',
      privacity: 'Privacy',
      sharedWith: 'Condiviso con',
      empty: 'Nessun elemento',
      ifNotPrivate: 'Se non è privato, sarà condiviso con tutti gli utenti in base alle autorizzazioni sullo strumento',
      sharedNo: 'Non condiviso',
      itsPrivate: 'È privato',
      sharedUsers: 'Condiviso con %users% utenti',
      sharedGroups: 'Condiviso con %groups% gruppi',
      sharedBoth: 'Condiviso con %users% utenti e %groups% gruppi',
      privateDetails: 'Privato: Solo gli utenti con autorizzazioni possono accedere a questo elemento',
      restrictedDetails: 'Limitato: Tutti gli utenti con accesso allo strumento possono accedere a questo elemento',
      publicDetails: 'Pubblico: Tutti gli utenti possono accedere a questo elemento',
      public: 'Pubblico',
      restricted: 'Limitato',
      cantShareExternal: "Questa risorsa è privata poiché non hai accesso alla startup, sarai l'unico a poterla visualizzare",
      cantShareUnauthorized: "Questa risorsa è privata poiché non hai autorizzazioni sufficienti nello strumento, sarai l'unico a poterla visualizzare",
      shareWithManagers: 'Condividi con i manager se vengo accettato nella startup',
      tools: {
        capTable: 'Tabella dei partecipanti',
        performance: 'Prestazioni',
        dataRoom: 'Documenti',
        profile: 'Profilo',
        mentoring: 'Mentoraggio',
        reporting: 'Segnalazione',
        users: 'Gestione utenti',
        messages: 'Messaggi',
        services: 'Servizi',
        fundraise: 'Raccolta fondi',
      },
      levels: {
        0: 'Nessuna autorizzazione',
        1: 'Visualizza',
        2: 'Modifica',
        3: 'Gestisci',
      },
      defaultGroups: {
        owner: 'Proprietari (predefinito)',
        admin: 'Amministratori (predefinito)',
        user: 'Utenti (predefinito)',
        shareholder: 'Investitori (predefinito)',
        other: 'Altro (predefinito)',
        ESOPs: 'ESOPs (predefinito)',
        startup: 'Utenti della startup (predefinito)',
      },
    },
    signature: {
      uploadDocumentToBeSigned: 'Carica documento da firmare',
      subject: 'Oggetto',
      message: 'Messaggio',
      signatureStatus: 'Stato della firma',
      downloadDocument: 'Scarica documento',
      downloadAudit: 'Scarica audit',
      signatureZone: 'Zona della firma',
      topLeft: 'In alto a sinistra',
      topRight: 'In alto a destra',
      bottomLeft: 'In basso a sinistra',
      bottomRight: 'In basso a destra',
      sendTo: 'Invia a',
      status: {
        email_processed: 'La email è stata elaborata',
        email_delivered: 'La email è stata consegnata',
        email_opened: 'La email è stata aperta',
        email_bounced: 'Il server non può consegnare il messaggio. I rimbalzi sono spesso causati da indirizzi email obsoleti o errati.',
        email_deferred: "L'email non può essere consegnata immediatamente, ma non è stata completamente respinta. A volte è chiamato rimbalzo morbido e verrà ritentato per 72 ore.",
        reminder_email_processed: 'La email di promemoria è stata elaborata',
        reminder_email_delivered: 'La email di promemoria è stata consegnata',
        reminder_email_opened: 'La email di promemoria è stata aperta',
        sms_processed: 'Il SMS è stato elaborato',
        sms_delivered: 'Il SMS è stato consegnato',
        password_sms_processed: 'Il SMS della password è stato elaborato',
        password_sms_delivered: 'Il SMS della password è stato consegnato',
        document_opened: 'Il documento è stato aperto',
        document_signed: 'Il documento è stato firmato',
        document_completed: 'Il documento è stato completato ed è pronto per essere scaricato',
        audit_trail_completed: 'Il percorso di audit è stato completato ed è pronto per essere scaricato',
        document_declined: 'Il documento è stato rifiutato',
        document_expired: 'Il documento è scaduto',
        document_canceled: 'Il documento è stato annullato',
        photo_added: 'Il firmatario ha allegato una foto nel processo',
        voice_added: 'Il firmatario ha allegato un audio nel processo',
        file_added: 'Il firmatario ha allegato un file nel processo',
        photo_id_added: "Il firmatario ha allegato un'identificazione con foto nel processo",
        terms_and_conditions_accepted: 'Il firmatario ha accettato i termini e le condizioni',
      },
    },
    onboarding: {
      welcome: 'Benvenuto su ',
      subtitle: 'Da questa piattaforma potrai connetterti con mentori che possono aiutarti a far crescere il tuo progetto',
      subtitleMentor: 'Da questa piattaforma potrai connetterti con startup per aiutarle a far crescere il loro progetto',
      subtitleFund: '',
      letsStart: 'Iniziamo :)',
      companyData: 'Dati della startup',
      mentorData: 'Dati del mentore',
      fundData: 'Dati del fondo',
      presentation: 'Presentazione',
      preferences: 'Preferenze',
      companyName: "Nome dell'azienda",
      name: 'Nome',
      startupName: 'Nome della startup',
      externalId: 'ID esterno',
      taxid: 'ID fiscale',
      url: 'URL',
      type: 'Tipo',
      select: 'Seleziona',
      sectors: 'Settori',
      describeWhatYourCompanyDoes: 'Descrivi cosa fa la tua startup',
      inviteColleages: 'Invita i colleghi',
      back: 'Indietro',
      headline: 'Bio',
      whatDoYouSpendYourTimeOn: 'A cosa dedichi il tuo tempo? CEO, COO, ... Investitore, Business angel, ...',
      company: 'Azienda',
      country: 'Paese',
      city: 'Città',
      legalForm: 'Forma giuridica',
      legalName: 'Nome legale',
      constitutionDate: 'Data di costituzione',
      hoursToDonate: 'Ore da donare',
      hours: 'Ore',
      knowledgeSector: 'Settore/i',
      knowledgeSectorMentor: 'Settore/i di conoscenza (in cui puoi aiutare)',
      areasOfExpertise: 'Area/e',
      areasOfExpertiseMentor: 'Aree di competenza (in cui puoi aiutare)',
      businessTypes: 'Tipo di business',
      businessTypesMentor: 'Tipi di business (in cui puoi aiutare)',
      advancedMaterials: 'Materiali avanzati',
      advancedMaterialsMentor: 'Materiali avanzati (dove puoi aiutare)',
      agrotech: 'Agrotech',
      api: 'API',
      artificialIntelligence: 'Intelligenza artificiale',
      augmentedReality: 'Realtà aumentata',
      autonomousMachines: 'Macchine autonome',
      bigData: 'Big Data',
      blockchain: 'Blockchain',
      businessIntelligence: 'Business intelligence',
      cloud: 'Cloud',
      communities: 'Comunità',
      connectivity: 'Connettività',
      consumerGoods: 'Beni di consumo',
      crm: 'CRM',
      crowdsourcing: 'Crowdsourcing',
      customerService: 'Servizio clienti',
      cybersecurity: 'Cybersecurity',
      design: 'Design',
      developmentTools: 'Strumenti di sviluppo',
      domotics: 'Domotica',
      drones: 'Droni',
      eCommerce: 'Commercio elettronico (mercato di prodotti o servizi)',
      education: 'Educazione',
      eHealth: 'eSalute',
      enablers: 'Abilitatori',
      engineering: 'Ingegneria',
      entertainment: 'Intrattenimento',
      erp: 'ERP',
      finTech: 'FinTech',
      fishing: 'Pesca',
      fitness: 'Fitness',
      games: 'Giochi',
      hospitality: 'Ospitalità',
      hr: 'HR',
      'industry4.0': 'Industria 4.0',
      insurtech: 'Insurtech',
      intelligentMobility: 'Mobilità intelligente',
      iot: 'Internet delle cose',
      legalTech: 'Legaltech',
      lifestyle: 'Stile di vita',
      location: 'Posizione',
      logisticsAndTransportation: 'Logistica e trasporti (merci)',
      loyalty: 'Fedeltà',
      m2m: 'M2M',
      machineLearning: 'Apprendimento automatico',
      nanotechnology: 'Nanotecnologia',
      'rv-ra-rm': 'RV-RA-RM',
      robotics: 'Robotica',
      others: '+ Altri',
      b2c: 'B2C',
      b2b: 'B2B',
      c2b: 'C2B',
      c2c: 'C2C',
      b2g: 'B2G',
      d2c: 'D2C',
      generalDirection: 'Direzione generale',
      humanResources: 'Risorse umane',
      FinanceAndAccounting: 'Finanza e contabilità',
      MarketingAndSales: 'Marketing e vendite',
      production: 'Produzione',
      itTechnology: "Tecnologia dell'informazione",
      innovation: 'Innovazione',
      start: 'Inizia!',
      previous: 'Precedente',
      next: 'Successivo',
      uploadLogo: 'Carica il tuo logo',
      uploadAvatar: 'Carica il tuo avatar',
      logo: 'Logo',
      description: 'Descrizione breve',
      avatar: 'Avatar',
      lastname: 'Cognome',
      founders: 'Fondatori',
      foundedDate: 'Data di fondazione',
      firstname: 'Nome',
      see: 'Vedi',
      modify: 'Modifica',
      delete: 'Elimina',
      users: 'Utenti',
      searchStartup: "Scopri se la tua startup è già nel nostro database, inserisci il nome qui sotto e, nel caso non esista, potrai procedere con l'aggiunta di tutte le informazioni.",
      searchFund: "Scopri se il tuo fondo è già nel nostro database, inserisci il nome qui sotto e, nel caso non esista, potrai procedere con l'aggiunta di tutte le informazioni.",
      writeStartupName: 'Scrivi il nome della tua startup',
      writeFundName: 'Scrivi il nome del tuo fondo',
      messageOwnerExist: "La startup %STARTUP_NAME% ha già un proprietario. Puoi richiedere l'accesso premendo il pulsante sottostante.",
      accessRequestSent: 'Richiesta di accesso inviata. Il proprietario della startup %STARTUP_NAME% risponderà alla tua richiesta il prima possibile.',
      accessRequestPending: 'È già presente una richiesta di accesso a %STARTUP_NAME% in sospeso. Il proprietario della startup risponderà alla tua richiesta il prima possibile.',
      accessRequestRejected: 'Ci dispiace, il proprietario della startup %STARTUP_NAME% ha respinto la tua richiesta di accesso.',
      request: 'Richiedi',
      requested: 'Richiesto',
      requestToAdmin: "Richiedi all'amministratore",
      requestSentSuccessfully: 'La tua richiesta di accesso è stata inviata con successo. Nel frattempo, puoi continuare a navigare come ospite.',
      user_already_exist: 'Questo utente fa già parte della startup',
      request_already_exist: 'Esiste già una richiesta',
      verifyProperty: 'Verifica proprietà',
      requestAccess: 'Richiedi accesso',
      messageValidationRequired: "Dobbiamo verificare che la startup %STARTUP_NAME% sia di tua proprietà. Hai tre opzioni: accedi con un'email della startup, effettua una verifica DNS o richiedi l'accesso a un amministratore della piattaforma.",
      messageValidationRequiredExternal: "Dobbiamo verificare che la startup %STARTUP_NAME% sia di tua proprietà. Hai due opzioni: accedi con un'email della startup o effettua una verifica DNS.",
      requestAccessToAdmin: "Non siamo riusciti a verificare che la startup %STARTUP_NAME% sia di tua proprietà. Richiedi l'accesso a un amministratore della piattaforma.",
      messageValidationDNS: 'Crea un record DNS di tipo TXT nel dominio %DOMAIN% con valore "%DNS_VALUE%"',
      accessWithoutPermission: 'Continua come ospite',
      retry: 'Riprova',
      couldNotVerify: 'Impossibile verificare',
      deleteStartup: 'Sei sicuro di voler eliminare la startup? Se elimini la startup, tutti i dati verranno eliminati permanentemente',
      fundManager: 'Nome del gestore del fondo',
      address: 'Indirizzo',
      managementCompanyCountry: "Paese dell'azienda gestore",
      managementCompanyAddress: "Indirizzo dell'azienda gestore",
      fundRegulator: 'Regolatore del fondo',
      subfund: 'Sottofondo',
      lieAlreadyExist: 'Il fondo che stai cercando di creare è già stato registrato ed è in fase di revisione',
      lieAlreadyExistVerified: 'Esiste già un fondo registrato con lo stesso identificatore',
      institutionType: {
        title: 'Tipo di istituzione',
        opened: 'Aperto',
        closed: 'Chiuso',
      },
      investmentPolicy: {
        title: 'Politica di investimento',
        ventureCapital: 'Capitale di rischio',
        privateEquity: 'Capitale privato',
        corporateVentureCapital: 'Capitale di rischio aziendale',
        realEstate: 'Investimenti immobiliari',
        privateDebt: 'Debito privato',
      },
      countries: {
        AFG: 'Afghanistan',
        ALA: 'Åland',
        ALB: 'Albania',
        DZA: 'Algeria',
        ASM: 'Samoa Americane',
        AND: 'Andorra',
        AGO: 'Angola',
        AIA: 'Anguilla',
        ATA: 'Antartide',
        ATG: 'Antigua e Barbuda',
        ARG: 'Argentina',
        ARM: 'Armenia',
        ABW: 'Aruba',
        AUS: 'Australia',
        AUT: 'Austria',
        AZE: 'Azerbaigian',
        BHS: 'Bahamas',
        BHR: 'Bahrein',
        BGD: 'Bangladesh',
        BRB: 'Barbados',
        BLR: 'Bielorussia',
        BEL: 'Belgio',
        BLZ: 'Belize',
        BEN: 'Benin',
        BMU: 'Bermuda',
        BTN: 'Bhutan',
        BOL: 'Bolivia',
        BES: 'Bonaire, Sint Eustatius e Saba',
        BIH: 'Bosnia-Erzegovina',
        BWA: 'Botswana',
        BVT: 'Isola Bouvet',
        BRA: 'Brasile',
        IOT: 'Territorio britannico dell\'Oceano Indiano',
        BRN: 'Brunei',
        BGR: 'Bulgaria',
        BFA: 'Burkina Faso',
        BDI: 'Burundi',
        KHM: 'Cambogia',
        CMR: 'Camerun',
        CAN: 'Canada',
        CPV: 'Capo Verde',
        CYM: 'Isole Cayman',
        CAF: 'Repubblica Centrafricana',
        TCD: 'Ciad',
        CHL: 'Cile',
        CHN: 'Cina',
        CXR: 'Isola Christmas',
        CCK: 'Isole Cocos',
        COL: 'Colombia',
        COM: 'Comore',
        COG: 'Congo',
        COD: 'Repubblica Democratica del Congo',
        COK: 'Isole Cook',
        CRI: 'Costa Rica',
        CIV: 'Costa d\'Avorio',
        HRV: 'Croazia',
        CUB: 'Cuba',
        CUW: 'Curaçao',
        CYP: 'Cipro',
        CZE: 'Repubblica Ceca',
        DNK: 'Danimarca',
        DJI: 'Gibuti',
        DMA: 'Dominica',
        DOM: 'Repubblica Dominicana',
        ECU: 'Ecuador',
        EGY: 'Egitto',
        SLV: 'El Salvador',
        GNQ: 'Guinea Equatoriale',
        ERI: 'Eritrea',
        EST: 'Estonia',
        ETH: 'Etiopia',
        FLK: 'Isole Falkland',
        FRO: 'Isole Faroe',
        FJI: 'Figi',
        FIN: 'Finlandia',
        FRA: 'Francia',
        GUF: 'Guiana Francese',
        PYF: 'Polinesia Francese',
        ATF: 'Terre australi e antartiche francesi',
        GAB: 'Gabon',
        GMB: 'Gambia',
        GEO: 'Georgia',
        DEU: 'Germania',
        GHA: 'Ghana',
        GIB: 'Gibilterra',
        GRC: 'Grecia',
        GRL: 'Groenlandia',
        GRD: 'Grenada',
        GLP: 'Guadalupa',
        GUM: 'Guam',
        GTM: 'Guatemala',
        GGY: 'Guernsey',
        GIN: 'Guinea',
        GNB: 'Guinea-Bissau',
        GUY: 'Guyana',
        HTI: 'Haiti',
        HMD: 'Isole Heard e McDonald',
        VAT: 'Città del Vaticano',
        HND: 'Honduras',
        HKG: 'Hong Kong',
        HUN: 'Ungheria',
        ISL: 'Islanda',
        IND: 'India',
        IDN: 'Indonesia',
        IRN: 'Iran',
        IRQ: 'Iraq',
        IRL: 'Irlanda',
        IMN: 'Isola di Man',
        ISR: 'Israele',
        ITA: 'Italia',
        JAM: 'Giamaica',
        JPN: 'Giappone',
        JEY: 'Jersey',
        JOR: 'Giordania',
        KAZ: 'Kazakistan',
        KEN: 'Kenya',
        KIR: 'Kiribati',
        PRK: 'Corea del Nord',
        KOR: 'Corea del Sud',
        KWT: 'Kuwait',
        KGZ: 'Kirghizistan',
        LAO: 'Laos',
        LVA: 'Lettonia',
        LBN: 'Libano',
        LSO: 'Lesotho',
        LBR: 'Liberia',
        LBY: 'Libia',
        LIE: 'Liechtenstein',
        LTU: 'Lituania',
        LUX: 'Lussemburgo',
        MAC: 'Macao',
        MKD: 'Macedonia del Nord',
        MDG: 'Madagascar',
        MWI: 'Malawi',
        MYS: 'Malesia',
        MDV: 'Maldive',
        MLI: 'Mali',
        MLT: 'Malta',
        MHL: 'Isole Marshall',
        MTQ: 'Martinica',
        MRT: 'Mauritania',
        MUS: 'Mauritius',
        MYT: 'Mayotte',
        MEX: 'Messico',
        FSM: 'Micronesia',
        MAR: 'Marocco',
        MDA: 'Moldavia',
        MCO: 'Monaco',
        MNG: 'Mongolia',
        MNE: 'Montenegro',
        MSR: 'Montserrat',
        MOZ: 'Mozambico',
        MMR: 'Myanmar',
        NAM: 'Namibia',
        NRU: 'Nauru',
        NPL: 'Nepal',
        NLD: 'Paesi Bassi',
        NCL: 'Nuova Caledonia',
        NZL: 'Nuova Zelanda',
        NIC: 'Nicaragua',
        NER: 'Niger',
        NGA: 'Nigeria',
        NIU: 'Niue',
        NFK: 'Isola Norfolk',
        MNP: 'Isole Marianne Settentrionali',
        NOR: 'Norvegia',
        OMN: 'Oman',
        PAK: 'Pakistan',
        PLW: 'Palau',
        PSE: 'Palestina',
        PAN: 'Panama',
        PNG: 'Papua Nuova Guinea',
        PRY: 'Paraguay',
        PER: 'Perù',
        PHL: 'Filippine',
        PCN: 'Isole Pitcairn',
        POL: 'Polonia',
        PRT: 'Portogallo',
        PRI: 'Portorico',
        QAT: 'Qatar',
        REU: 'Riunione',
        ROU: 'Romania',
        RUS: 'Russia',
        RWA: 'Ruanda',
        BLM: 'Saint-Barthélemy',
        SHN: 'Sant\'Elena, Ascensione e Tristan da Cunha',
        KNA: 'Saint Kitts e Nevis',
        LCA: 'Santa Lucia',
        MAF: 'Saint-Martin',
        SPM: 'Saint-Pierre e Miquelon',
        VCT: 'Saint Vincent e Grenadine',
        WSM: 'Samoa',
        SMR: 'San Marino',
        STP: 'Sao Tomé e Principe',
        SAU: 'Arabia Saudita',
        SEN: 'Senegal',
        SRB: 'Serbia',
        SYC: 'Seychelles',
        SLE: 'Sierra Leone',
        SGP: 'Singapore',
        SXM: 'Sint Maarten',
        SVK: 'Slovacchia',
        SVN: 'Slovenia',
        SLB: 'Isole Solomon',
        SOM: 'Somalia',
        ZAF: 'Sudafrica',
        SGS: 'Georgia del Sud e Isole Sandwich meridionali',
        SSD: 'Sud Sudan',
        ESP: 'Spagna',
        LKA: 'Sri Lanka',
        SDN: 'Sudan',
        SUR: 'Suriname',
        SJM: 'Svalbard e Jan Mayen',
        SWZ: 'Swaziland',
        SWE: 'Svezia',
        CHE: 'Svizzera',
        SYR: 'Siria',
        TWN: 'Taiwan',
        TJK: 'Tagikistan',
        TZA: 'Tanzania',
        THA: 'Thailandia',
        TLS: 'Timor Est',
        TGO: 'Togo',
        TKL: 'Tokelau',
        TON: 'Tonga',
        TTO: 'Trinidad e Tobago',
        TUN: 'Tunisia',
        TUR: 'Turchia',
        TKM: 'Turkmenistan',
        TCA: 'Isole Turks e Caicos',
        TUV: 'Tuvalu',
        UGA: 'Uganda',
        UKR: 'Ucraina',
        ARE: 'Emirati Arabi Uniti',
        GBR: 'Regno Unito',
        USA: 'Stati Uniti d\'America',
        UMI: 'Isole minori esterne degli Stati Uniti d\'America',
        URY: 'Uruguay',
        UZB: 'Uzbekistan',
        VUT: 'Vanuatu',
        VEN: 'Venezuela',
        VNM: 'Vietnam',
        VGB: 'Isole Vergini Britanniche',
        VIR: 'Isole Vergini Americane',
        WLF: 'Wallis e Futuna',
        ESH: 'Sahara Occidentale',
        YEM: 'Yemen',
        ZMB: 'Zambia',
        ZWE: 'Zimbabwe',
      },
      language: 'Lingua',
      role: 'Ruolo',
      email: 'Email',
      languages: {
        es: 'Spagnolo',
        en: 'Inglese',
        it: 'Italiano',
      },
      mainCurrency: 'Valuta principale',
      mentorVisible: 'Disponibilità a ricevere nuove richieste di mentoraggio',
      yes: 'Sì',
      no: 'No',
      venture_capital: 'Capitale di rischio',
      funds_of_funds: 'Fondi di fondi',
      debt: 'Debito',
      other: 'Altro',
      minPerProject: 'Investimento minimo per progetto',
      maxPerProject: 'Investimento massimo per progetto',
      phase: 'Fase',
      preseed: 'PRE-SEED',
      seed: 'SEED',
      early: 'INIZIALE',
      growth: 'CRESCITA',
      expansion: 'ESPANSIONE',
      industries: 'Industrie',
      health: 'Salute',
      fintech: 'Fintech',
      food: 'Cibo',
      media: 'Media',
      marketing: 'Marketing',
      enterpriseSoftware: 'Software aziendale',
      transportation: 'Trasporti',
      fashion: 'Moda',
      realEstate: 'Immobiliare',
      homeLiving: 'Vita domestica',
      kids: 'Bambini',
      energy: 'Energia',
      wellnessBeauty: 'Benessere e bellezza',
      sports: 'Sport',
      hosting: 'Hosting',
      gaming: 'Giochi',
      travel: 'Viaggi',
      semiconductors: 'Semiconduttori',
      music: 'Musica',
      eventTech: 'Tecnologia degli eventi',
      jobsRecruitment: 'Lavori e reclutamento',
      security: 'Sicurezza',
      legal: 'Legale',
      telecom: 'Telecomunicazioni',
      dating: 'Appuntamenti',
      serviceProvider: 'Fornitore di servizi',
      engineeringAndManufacturingEquipment: 'Attrezzature di ingegneria e produzione',
      space: 'Spazio',
      consumerElectronics: 'Elettronica di consumo',
      chemicals: 'Prodotti chimici',
      subindustries: 'Sottoindustrie',
      healthPlatform: 'Piattaforma sanitaria',
      biotechnology: 'Biotecnologia',
      medicalDevices: 'Dispositivi medici',
      pharmaceutical: 'Farmaceutico',
      financialManagementSolutions: 'Soluzioni di gestione finanziaria',
      wealthManagement: 'Gestione del patrimonio',
      payments: 'Pagamenti',
      mortgagesAndLending: 'Mutui e prestiti',
      insurance: 'Assicurazioni',
      cryptoAndDeFi: 'Criptovalute e DeFi',
      banking: 'Bancario',
      regtech: 'Regtech',
      innovativeFood: 'Alimenti innovativi',
      agritech: 'Agritech',
      foodLogisticsAndDelivery: 'Logistica e consegna di cibo',
      inStoreRetailAndRestaurantTech: 'Tecnologia per il commercio al dettaglio e i ristoranti',
      kitchenAndCookingTech: 'Tecnologia da cucina e di cottura',
      contentProduction: 'Produzione di contenuti',
      publishing: 'Pubblicazione',
      socialMedia: 'Social media',
      streaming: 'Streaming',
      adtech: 'Pubblicità online (adtech)',
      cRMAndSales: 'CRM e vendite',
      ecommerceSolutions: 'Soluzioni di e-commerce',
      marketingAnalytics: 'Analisi di marketing',
      logisticsAndDelivery: 'Logistica e consegna',
      mobility: 'Mobilità',
      vehicleProduction: 'Produzione di veicoli',
      searchBuyAndRent: 'Ricerca, acquisto e noleggio',
      navigationAndMapping: 'Navigazione e mappatura',
      maintenance: 'Manutenzione',
      autonomousAndSensorTech: 'Tecnologia autonoma e sensoriale',
      apparel: 'Abbigliamento',
      accessories: 'Accessori',
      luxury: 'Lusso',
      footwear: 'Calzature',
      construction: 'Costruzione',
      realEstateServices: 'Servizi immobiliari',
      realEstateSoftware: 'Software immobiliare',
      workspaces: 'Spazi di lavoro',
      learningToolsAndResources: 'Strumenti e risorse didattiche',
      educationProviders: 'Fornitori di educazione',
      educationManagement: "Gestione dell'educazione",
      cleanEnergy: 'Energia pulita',
      energyEfficiency: 'Efficienza energetica',
      oilAndGas: 'Petrolio e gas',
      wasteSolution: 'Soluzioni di gestione dei rifiuti',
      water: 'Acqua',
      energyProviders: 'Fornitori di energia',
      energyStorage: 'Stoccaggio di energia',
      sportPlatformAndApplication: 'Piattaforma e applicazione sportiva',
      sportingEquipment: 'Attrezzatura sportiva',
      sportLeagueAndClub: 'Lega e club sportivi',
      sportMedia: 'Media sportivi',
      sportSupplements: 'Integratori sportivi',
      consoleAndPCGaming: 'Giochi per console e PC',
      mobileGaming: 'Giochi per dispositivi mobili',
      bettingAndGambling: "Scommesse e gioco d'azzardo",
      esports: 'E-sport',
      boardGames: 'Giochi da tavolo',
      onlineTravelAgency: 'Agenzia di viaggi online',
      accommodation: 'Alloggio',
      bookingAndSearch: 'Prenotazioni e ricerca',
      travelAnalyticsAndSoftware: 'Analisi e software di viaggio',
      businessTravel: "Viaggi d'affari",
      publicSafety: 'Sicurezza pubblica',
      cloudAndInfrastructure: 'Cloud e infrastruttura',
      dataProtection: 'Protezione dei dati',
      identityAndAccess: 'Identità e accesso',
      deviceSecurityAndAntivirus: 'Sicurezza dei dispositivi e antivirus',
      consulting: 'Consulenza',
      incomeStreams: 'Flussi di reddito',
      subscription: 'Abbonamento',
      advertising: 'Pubblicità',
      commission: 'Commissione',
      sellingOwnInventory: 'Vendita di inventario proprio',
      revenues: 'Ricavi',
      saas: 'SaaS',
      marketplaces: 'Mercati online e e-commerce',
      manufacturing: 'Produzione (Prodotti fisici)',
      launchYear: 'Anno di lancio',
      growthStage: 'Fase di crescita',
      clientFocus: 'Focus sul cliente',
      earlyGrowth: 'Crescita iniziale',
      lateGrowth: 'Crescita tardiva',
      mature: 'Matura',
      shareWithFounder: 'Condividi con il fondatore',
      inviteFounder: 'Invita il fondatore',
      invitationManageStartup: 'Invita a gestire la startup',
      invitationManageStartupMessage: 'Sei stato invitato a gestire la startup %STARTUP%',
      logout: 'Disconnettersi',
    },
    mainpage: {
      wellcome: 'Benvenuto',
    },
    mentoring: {
      title: 'Mentoraggio',
      mentoring: 'Mentoraggio',
      mentorings: 'Mentoraggi',
      startups: 'Startup',
      mentors: 'Mentori',
      yourMentorings: 'I tuoi mentoraggi',
      searchMentors: 'Cerca mentori',
      searchStartups: 'Cerca startup',
      finished: 'Terminati',
      pending: 'In attesa',
      seekingMentors: 'Cerca mentori',
      seekingStartups: 'Cerca startup',
      startMentoringsMentors: 'Inizia a inviare richieste ai mentori per avviare il tuo processo di mentoraggio.',
      startMentoringsStartups: 'Inizia a inviare richieste alle startup per avviare il tuo processo di mentoraggio.',
      consumerHours: 'Ore consumate',
      nextMeeting: 'Prossimo incontro',
      lastInteraction: 'Ultima interazione',
      knowledgeSectors: 'Settori di conoscenza',
      confirm: 'Conferma',
      accept: 'Accetta',
      accepted: 'Accettata',
      reject: 'Rifiuta',
      rejected: 'Rifiutata',
      enter: 'Entra',
      moreInfo: 'Maggiori informazioni',
      invite: 'Invita',
      cancel: 'Annulla',
      canceled: 'Annullata',
      soon: 'Presto',
      letsGo: 'Andiamo!',
      invitationSent: 'La tua richiesta è stata inviata',
      emptyInviteMessage: 'Devi scrivere un messaggio',
      whatCanYouContribute: 'Cosa puoi contribuire a una startup?',
      howCanTheMentorHelpYou: 'Come può aiutarti il mentore?',
      rejectMentoring: 'Rifiuta il mentoraggio',
      confirms: {
        cancel: 'Sei sicuro di voler cancellare il mentoraggio?',
        accepted: 'Sei sicuro di voler accettare il mentoraggio?',
        rejected: 'Sei sicuro di voler rifiutare il mentoraggio?',
      },
      explainTheReasonTo: 'Spiega il motivo a',
      invitationMessage: 'Messaggio di invito:',
      rejectionReason: 'Motivo del rifiuto:',
      lessInfo: 'Meno informazioni',
      closeInvite: 'Chiudi invito',
      filter: 'Filtri',
      recommended: 'Raccomandata',
      noResults: 'Nessun risultato trovato',
      areasOfExpertise: 'Aree di competenza',
      preferredLanguage: 'Lingua preferita',
      businessType: 'Tipo di attività',
      socialImpact: 'Impatto sociale',
      startupCountry: 'Paese della startup',
      startupDevelopmentStage: 'Fase di sviluppo della startup',
      sectors: 'Settori',
      companyAreas: 'Aree aziendali',
      resetFilters: 'Reimposta filtri',
      applyFilters: 'Applica filtri',
      appliedFilters: 'Filtri applicati',
      select: 'Seleziona',
      search: 'Cerca',
      next: 'Successivo',
      events: 'Eventi',
      messages: 'Messaggi',
      documents: 'Documenti',
      reports: 'Report',
      addMessage: 'Aggiungi messaggio',
      createMeeting: 'Crea incontro',
      meetingTime: 'Ore di incontro',
      editMeeting: 'Modifica incontro',
      uploadDocument: 'Carica documento',
      emptyDocuments: 'Nessun documento',
      createReport: 'Crea report',
      editReport: 'Modifica report',
      emptyReports: 'Nessun report',
      finish: 'Termina',
      giveFeedback: 'Fornisci feedback',
      feedbackSent: 'Feedback inviato',
      finishButtonTitle: 'Termina mentoraggio e fornisci feedback',
      startedBy: 'Iniziato da',
      feedbackForm: {
        title: 'Feedback del mentoraggio',
        titleEnd: 'Terminare mentoraggio',
        description: '',
        descriptionEnd: '',
        reason: 'Quale azione vuoi eseguire?',
        cancel: 'Annulla mentoraggio (non completato/finalizzato)',
        cancelReason: 'Perché?',
        finish: 'Termina mentoraggio (completato)',
        finishComment: 'Come è andato?',
        score: 'Valutazione (5 stelle è il massimo)',
        send: 'Invia',
      },
      reportForm: {
        popupTitle: 'Crea report',
        popupTitleEdit: 'Modifica report',
        popupDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac dui convallis, suscipit quam sed, ornare felis. Mauris convallis ipsum ac iaculis lacinia. Quisqu',
        text: 'Crea un rapporto sulla tua attuale visione del progetto',
        send: 'Invia',
      },
      mentoringStatus: 'Stato della mentoring',
      status: {
        status: 'Stato',
        accepted: 'Accettata',
        finished: 'Completata',
        pending: 'In sospeso',
        rejected: 'Respinta',
        finish: 'Terminata',
        cancel: 'Annullata',
      },
      infoPopUp: {
        info: 'Informazioni sulla mentoring',
        status: 'Stato',
        creationDate: 'Data di creazione',
        finishDate: 'Data di completamento',
        creator: 'Creatore',
        guest: 'Ospite',
        invitationMessage: 'Messaggio di invito',
        rejectionReason: 'Motivo del rifiuto',
      },
      edit: 'Modifica',
      delete: 'Elimina',
      deleteReport: 'Sei sicuro di voler eliminare il report?',
      numberMessages: 'Messaggi inviati',
      numberEvents: 'Eventi creati',
      numberDocuments: 'Documenti caricati',
      numberReports: 'Numero di report',
      mentorNotVisible: 'Hai configurato nel tuo profilo che non ti possano essere richieste nuove mentoring',
    },
    shareholder: {
      modify: 'Modifica',
      deleteShareholder: 'Eliminare l\'azionista?',
      shareholders: 'Azionisti',
      cannotDeleteShareholder: 'Impossibile eliminare un azionista coinvolto in una qualsiasi operazione',
      shareholdersCreated: 'azionisti creati',
      downloadTemplate: 'Scarica modello',
      bulkShareholders: 'Carica azionisti',
      inviteUser: 'Invita utente',
      unbindSyndicate: 'Scollega sindacato',
      unbindSyndicateWarning: 'Sei sicuro di voler scollegare il sindacato?',
      bindingSyndicateError: 'Si è verificato un errore durante il collegamento/scollamento del sindacato',
      bindingError: 'Si è verificato un errore durante il collegamento/scollamento dell\'utente',
      email: 'Email',
      name: 'Nome',
      lastname: 'Cognome',
      permissions: 'Permessi',
      errorEmptyPerms: 'Non hai selezionato alcun permesso',
      lang: 'Lingua',
      languages: {
        es: 'Spagnolo',
        en: 'Inglese',
        it: 'Italiano',
      },
      createUser: 'Crea utente',
      bindUser: 'Collega utente',
      unbindUser: 'Scollega utente',
      shareholderHaveShares: 'Se l\'azionista ha azioni, l\'utente avrà permessi su:',
      summary: 'Sommario',
      summaryDescription: 'Riepilogo generale di tutti gli strumenti',
      myPosition: 'La mia posizione',
      myPositionDescription: 'Informazioni sull\'investimento all\'interno della startup',
      capTable: 'Cap table',
      capTableDescription: 'Cap table',
      capTableSummary: 'Riepilogo',
      capTableSummaryDescription: 'Riepilogo del cap table',
      capTableSecurities: 'Titoli',
      capTableSecuritiesDescription: 'Visualizza dettagli su azioni, debiti e opzioni',
      capTableOperations: 'Operazioni',
      capTableOperationsDescription: 'Visualizza i dettagli di tutte le operazioni confermate',
      performance: 'Prestazioni',
      performanceDescription: 'Visualizza indicatori chiave di prestazione',
      reporting: 'Rapporti',
      reportingDescription: 'Accesso a tutti i rapporti ricevuti',
      dataRoom: 'Documenti',
      dataRoomDescription: 'Accesso a tutti i documenti eccetto quelli di altri azionisti',
      syndicates: 'Sindacati',
      syndicate: 'Sindacato',
      infoSyndicate: 'Informazioni sul sindacato',
      createSyndicate: 'Crea sindacato',
      editSyndicate: 'Modifica sindacato',
      unifyShareholders: 'Unifica azionisti',
      confirmUnifyShareholders: 'Sei sicuro di voler unificare i %SHAREHOLDERS% azionisti nell\'azionista %SHAREHOLDER%?',
      founder: 'Fondatore',
      director: 'Direttore',
      boardMember: 'Membro del consiglio',
      investor: 'Investitore',
      employee: 'Dipendente',
      secretary: 'Segretario',
      advisor: 'Consulente',
      bank: 'Banca',
      corporate: 'Azienda',
      angelInvestor: 'Investitore angelo',
      privateEquity: 'Private equity',
      ventureCapital: 'Venture capital',
      friendsAndFamily: 'Amici e familiari',
      corporateVentureCapital: 'Corporate venture capital',
      other: 'Altro',
      downloadExcel: 'Scarica Excel',
      formShareholder: 'Modulo azionista',
      infoShareholder: 'Informazioni sull\'azionista',
      types: 'Tipi',
      role: 'Ruolo',
      individual: 'Individuale',
      organization: 'Organizzazione',
      firstname: 'Nome',
      phone: 'Telefono',
      address: 'Indirizzo',
      country: 'Paese',
      taxid: 'TAXID',
      save: 'Salva',
      shares: 'Azioni',
      debts: 'Debiti',
      options: 'Opzioni',
      pps: 'PPS',
      numeration: 'Numerazione',
      shareClass: 'Classe azioni',
      capital: 'Capitale',
      contributedCapital: 'Capitale contribuito',
      principal: 'Principale',
      capitalized: 'Capitalizzato',
      repaid: 'Rimborsato',
      interestRate: 'Tasso di interesse',
      accrualFrequency: 'Frequenza di capitalizzazione',
      issueDate: 'Data di emissione',
      startDate: 'Data di inizio',
      expirationDate: 'Data di scadenza',
      number: 'Numero',
      assigned: 'Assegnato',
      vested: 'Acquisito',
      exercised: 'Esercitato',
      pending: 'In sospeso',
      vestingPlan: 'Piano di acquisizione',
      vestingDate: 'Data di acquisizione',
      phantom: 'Azioni fantasma',
      stockOption: 'Opzioni su azioni',
      since: 'Dal',
      partner: 'Partner',
      transactions: 'Transazioni',
      users: 'Utenti',
      documents: 'Documenti',
      captable: 'Cap Table',
      shareholder: 'Azionista',
      ownership: 'Possesso',
      fullyDiluted: 'Diluizione totale',
      historicalOperations: 'Operazioni storiche',
      usersLinked: 'Utenti collegati',
      avatar: 'Avatar',
      value: 'Valore',
      linkToMyUser: 'Collega al mio utente',
      unbindWarning: 'Sei sicuro di voler scollegare l\'utente?',
      noParticipation: 'L\'azionista non ha partecipazioni nel cap table',
      from: 'Da',
      to: 'A',
      optionsIn: 'Opzioni in',
      operation: 'Operazione',
      vote: 'Voto',
      status: 'Stato',
      closed: 'Chiuso',
      shareDetails: 'Dettagli azione',
      debtDetails: 'Dettagli debito',
      optionDetails: 'Dettagli opzione',
      price: 'Prezzo',
      exercisePrice: 'Prezzo di esercizio',
      ratio: 'Rapporto',
      percentage: 'Percentuale',
      numberOfShares: 'Numero di azioni',
      historial: 'Cronologia',
      paid: 'Pagato',
      debt: 'Debito',
      interest: 'Interesse',
      addUser: 'Aggiungi utente',
      invite: 'Invita',
      emptyFields: 'Compila tutti i campi per i nuovi utenti',
      errUserCreate: 'Errore durante la creazione dell\'utente con email:',
      investmentEvolution: 'Evoluzione dell\'investimento',
      debtsEvolution: 'Evoluzione dei debiti',
      optionsEvolution: 'Evoluzione delle opzioni',
      noSyndicate: 'Nessun sindacato',
      certificate: 'Certificato',
      newUser: 'Nuovo utente',
      existingUser: 'Utente esistente',
    },
    vestingsPlans: {
      addVestingPlan: 'Aggiungi piano di acquisizione',
      name: 'Nome',
      type: 'Tipo',
      createdAt: 'Creato il',
      questionDelete: 'Eliminare il piano di acquisizione?',
      questionDeleteError: 'Impossibile eliminare un piano di acquisizione con opzioni',
      vestingsPlans: 'Piani di acquisizione',
      basic: 'Base',
      time: 'Tempo',
      milestones: 'Obiettivi',
      hybrid: 'Ibrido',
      calendar: 'Calendario',
      shareholder: 'Investitore',
      confirmMilestones: 'Conferma i traguardi',
      showCalendar: 'Mostra calendario',
      vestingPlanCalendar: 'Calendario del piano di acquisizione',
      title: 'Titolo',
      percentage: 'Percentuale',
      addMilestone: 'Aggiungi Obiettivo',
      period: 'Periodo',
      cliff: 'Cliff',
      intervals: 'Intervalli',
      day: 'Giorno',
      month: 'Mese',
      year: 'Anno',
      start: 'Inizio',
      startDate: 'Data di inizio',
      exact: 'Giorno esatto',
      end: 'Fine',
      length: 'Durata',
      frequency: 'Frequenza',
      schedule: 'Calendario',
      addTime: 'Aggiungi Periodo',
      periods: 'Periodi',
      overallPercentage: 'Percentuale complessiva',
      save: 'Salva',
      formVestingsPlans: 'Modulo piani di acquisizione',
      infoVestingsPlans: 'Informazioni sui piani di acquisizione',
      capital: 'Capitale',
      expirationDate: 'Data di scadenza',
      number: 'Quantità',
      vested: 'Acquisito',
      exercised: 'Esercitato',
      phantom: 'Azioni fantasma',
      stockOption: 'Opzioni su azioni',
      modifyWarning: 'Piano di acquisizione con opzioni. Alcuni campi non possono essere modificati',
    },
    shareClass: {
      deleteClass: 'Eliminare la classe?',
      deleteClassError: 'Impossibile eliminare una classe con azioni',
      name: 'Nome',
      code: 'Codice',
      type: 'Tipo',
      modify: 'Modificare',
      delete: 'Eliminare',
      createClass: 'Creare classe',
      shareClasses: 'Classi di azioni',
      vote: 'Voto',
      unifyShareClasses: 'Unificare classi di azioni',
      confirmUnifyShareClasses: 'Sei sicuro di voler unificare le %SHARECLASSES% classi di azioni nella classe %SHARECLASS%?',
      preferred: 'Preferita',
      common: 'Comune',
      downloadExcel: 'Scaricare excel',
      save: 'Salvare',
      yes: 'Sì',
      no: 'No',
      liquidity: 'Liquidità',
      participation: 'Partecipazione',
      dividend: 'Dividendo',
      convert: 'Convertibile',
      ratio: 'Rapporto',
      price: 'Prezzo',
      day: 'Giorno',
      month: 'Mese',
      year: 'Anno',
      cash: 'Contanti',
      stock: 'Azione',
      value: 'Valore',
      frequency: 'Frequenza',
      compounding: 'Composizione',
      seniority: 'Priorità',
      multiple: 'Multiplo',
      cap: 'Cap',
      FormShareClass: 'Modulo di classe di azioni',
      votesPerShare: 'Voti per azione',
      InfoShareClass: 'Informazioni sulla classe di azioni',
      shares: 'Azioni',
      pps: 'PPS',
      numeration: 'Numerazione',
      capital: 'Capitale',
      phantom: 'Azioni fantasma',
      stockOption: 'Opzioni su azioni',
      modifyWarning: 'Classe con azioni. Si noti che qualsiasi modifica agli attributi della classe avrà effetto sulla cronologia del cap table.',
    },
    meetings: {
      confirmDelete: 'Sei sicuro di voler cancellarlo?',
      createMeeting: 'Creare incontro',
      edit: 'Modificare',
      delete: 'Eliminare',
      meetingTime: 'Durata',
      emptyMeetings: 'Non ci sono incontri',
      meetingForm: {
        popupTitle: 'Registra evento',
        popupDescription: 'Compila le informazioni con i dati dell\'evento/incontro che hai già fatto o che farai e che hai precedentemente concordato con il tuo mentore/mentore per registrarlo sulla piattaforma. Una volta salvato, potrai modificare/eliminare l\'evento. TI RICORDIAMO CHE QUESTO NON È UN INVITO A UN EVENTO.',
        title: 'Titolo',
        description: 'Descrizione',
        date: 'Data',
        hour: 'Ora',
        timezone: 'Fuso orario',
        time: 'Durata (minuti)',
        send: 'Invia',
        submitError: 'Errore nella creazione/modifica dell\'evento',
      },
      yourHours: 'Le tue ore',
      scheduledHours: 'Ore programmate',
      finishedHours: 'Ore effettuate',
      donatedHours: 'Ore donate',
    },
    legalConditions: {
      title: 'Abbiamo aggiornato i nostri termini',
      subTitle: 'Devi accettare le nuove condizioni prima di continuare.',
      actionBtn: 'Continua',
    },
    prehome: {
      selectStartup: 'Seleziona una startup',
      createStartup: 'Nuova startup',
      viewStartup: 'Visualizza startup',
      accept: 'Accetta',
      reject: 'Rifiuta',
      confirmAccept: 'Sei sicuro di voler accettare l\'invito?',
      confirmReject: 'Sei sicuro di voler rifiutare l\'invito?',
      user: 'Utente',
      mentor: 'Mentore',
      shareholder: 'Investitore',
      owner: 'Proprietario',
      other: 'Altro',
      admin: 'Amministratore',
      guest: 'Ospite',
      pendingApproval: 'In attesa di approvazione',
      myStartups: 'Le mie startup',
      viewMyPosition: 'Visualizza la mia posizione',
    },
    myInvestments: {
      title: 'La mia posizione',
      portfolio: 'Portafoglio',
      analysis: 'Analisi',
      opportunities: 'Opportunità',
      base: 'Base',
      currencies: 'Valute',
      investments: 'Investimenti',
      totalInvested: 'Totale investito',
      totalValue: 'Valore totale',
      multiplo: 'Multiplo',
      country: 'Paese',
      currency: 'Valuta',
      industry: 'Industria',
      currentPortfolio: 'Portafoglio attuale',
      divestedPortfolio: 'Portafoglio disinvestito',
      ownership: 'Possesso',
      cEquity: 'Capitale Azionario',
      cDebt: 'Debito',
      options: 'Opzioni',
      votes: 'Voti',
      noExchangeNecessary: 'Le valute dei tuoi investimenti sono le stesse di quelle selezionate',
      noResults: 'Non sei un investitore di nessuna startup',
      shares: 'Azioni',
      kpis: 'KPI',
      shareholder: 'Investitore',
      knowMore: 'Scopri di più',
      round: 'Round',
      referredBy: 'Riferito da',
      'pre-seed': 'PRE-SEED',
      seed: 'SEED',
      early: 'EARLY',
      growth: 'GROWTH',
      expansion: 'EXPANSION',
      entryDate: 'Data di entrata',
      comparator: 'Comparatore',
      numberOfShares: 'Numero di azioni',
    },
    input: {
      uploadFileError: 'Impossibile caricare il file',
      uploadFilezone: 'Fare clic qui per caricare un file',
      uploadFile: 'Carica un file',
      noOptions: 'Nessuna opzione trovata',
      enterToAdd: 'Premere ↵ per aggiungere',
      noneAbove: 'Nessuna delle precedenti',
      selected: 'Selezionati',
      of: 'di',
      private: 'Privato',
      share: 'Condividi',
      sharedWith: 'Condiviso con',
      users: 'utenti',
    },
    outputErrors: {
      required_error: 'Il campo è obbligatorio',
      requiredwith_error: 'Il campo è obbligatorio per questa condizione',
      requiredunless_error: 'Il campo è obbligatorio',
      max_error: 'Il campo supera il massimo consentito',
      unauthorized_error: 'Non hai i permessi per eseguire questa azione',
      unauthorized: 'Non hai i permessi per eseguire questa azione',
      internal: 'Si è verificato un errore imprevisto',
    },
    home: {
      summary: 'Sommario',
      companyDetails: 'Dettagli dell\'azienda',
      editCompany: 'Modifica profilo',
      viewCompany: 'Visualizza profilo',
      name: 'Nome',
      lastname: 'Cognome',
      founders: 'Fondatori',
      operatingStatus: 'Stato operativo',
      active: 'Attivo',
      foundedDate: 'Data di fondazione',
      lastFundingType: 'Ultimo tipo di finanziamento',
      headQuartersRegion: 'Regione della sede centrale',
      description: 'Descrizione',
      mentors: 'Mentori',
      mentorsSubtitle: 'Queste sono le mentorship attive della tua startup',
      noMentorings: 'Non hai nessuna mentorship attiva',
      noMeetings: 'Non hai incontri programmati',
      mentorDetails: 'Dettagli del mentore',
      mentorMoreHours: 'Hai superato il numero di ore che hai indicato di voler donare, se vuoi continuare a registrare incontri è necessario aumentarle.',
      mentorMoreHoursSoon: 'Presto supererai il numero di ore che hai indicato di voler donare, se vuoi continuare a registrare incontri è necessario aumentarle.',
      mentorMoreHoursProfile: 'Vai al profilo',
      mentorMoreHoursCancel: 'Annulla',
      editProfile: 'Modifica profilo',
      mentoredCompanies: 'Startup mentorizzate',
      donatedHours: 'Ore donate',
      donatedHoursLeft: 'Ore donate rimanenti / totale',
      events: 'Eventi',
      nextMeetings: 'Prossimi incontri',
      mentoringsEvents: 'Eventi delle mentorship',
      mentoringsList: 'Mentorship Attive',
      companyName: 'Nome dell\'azienda',
      consumerHours: 'Ore consumate',
      nextMeeting: 'Prossimo incontro:',
      lastComments: 'Ultimi commenti',
      readMore: 'Leggi di più',
      documents: 'Documenti',
      lastDocuments: 'Ultimi documenti',
      lastRound: 'Ultima round',
      totalFunding: 'Finanziamento totale',
      preMoneyValuation: 'Valutazione pre-denaro',
      postMoneyValuation: 'Valutazione post-denaro',
      investorUpdate: 'Aggiornamento dell\'investitore',
      kpis: 'KPI',
      country: 'Paese',
      headline: 'Titolo',
      sectors: 'Settori',
      areasOfExpertise: 'Aree di competenza',
      mentoringsEmpty: 'Non hai mentorship attive',
      documentsEmpty: 'Non ci sono documenti',
      eventsEmpty: 'Non ci sono eventi futuri',
      value: 'Valore',
      targetValue: 'Valore obiettivo',
      previousValue: 'Valore precedente',
      lastUpdate: 'Ultimo aggiornamento',
      noTools: 'Non hai accesso a nessun tool, contatta il proprietario',
      startupGuestInfo: 'Stai accedendo alla startup come ospite.',
      requestAccess: 'Richiedi accesso',
      addToPrehome: 'Aggiungi alla prehome',
      removeFromPrehome: 'Rimuovi dalla prehome',
      pendingApprovalRequest: 'La tua richiesta è in attesa di approvazione',
      requestSubmittedSuccessfully: 'Richiesta inviata con successo',
    },
    homeAdmin: {
      accepted: 'Accettate',
      pending: 'In sospeso',
      rejected: 'Respinte',
      finished: 'Concluse',
      mentorings: 'Mentorship',
      meetings: 'Incontri',
      documents: 'Documenti',
      startups: 'Startups',
      mentors: 'Mentori',
      groups: 'Gruppi',
      group1: 'Gruppo 1',
      group2: 'Gruppo 2',
      group3: 'Gruppo 3',
      users: 'Utenti',
      entrepreneurs: 'Imprenditori',
      mentoringStatus: 'Stato delle mentorship',
      lastMonthActivity: 'Attività dell\'ultimo mese',
      donatedHours: 'Ore donate',
      mentorsMentoring: 'Mentori che mentorano',
      startupMentored: 'Startups mentorizzate',
      meetingsFinished: 'Incontri completati',
      meetingsScheduled: 'Incontri pianificati',
      totalHours: 'Totale / Numero di ore',
      uploadedToMentorings: 'Caricati nelle mentorship',
      captable: 'Captable',
      operations: 'Operazioni',
      shareholders: 'Azionisti',
      shareholdersWithAccess: 'Azionisti con accesso',
      startupsWithCaptable: 'Startups con captable',
      syndicates: 'Sindacati',
      waterfalls: 'Waterfalls',
      performance: 'Performance',
      values: 'Valori',
      createdKpis: 'KPI creati',
      usedKpis: 'KPI utilizzati',
      valuesPerDay: 'Valori aggiunti al giorno',
      dataroom: 'Documenti',
      folders: 'Cartelle',
      reports: 'Report',
      emails: 'Email',
    },
    editInfo: {
      viewProfile: 'Visualizza profilo',
      editProfile: 'Modifica profilo',
      companyData: 'Dati aziendali',
      profileUpdated: 'Profilo aggiornato',
      submit: 'Invia',
      cancel: 'Annulla',
      save: 'Salva',
    },
    editAccount: {
      home: 'Home',
      editAccount: 'Modifica account',
      accountData: 'Dati dell\'account',
      avatar: 'Avatar',
      name: 'Nome',
      lastname: 'Cognome',
      email: 'Email',
      submit: 'Invia',
      cancel: 'Annulla',
      password: 'Password',
      oldPassword: 'Vecchia password',
      newPassword: 'Nuova password',
      newPasswordRepeat: 'Ripeti la nuova password',
      passwordNotMatch: 'Le password non corrispondono',
      accountSaved: 'Profilo salvato correttamente',
      language: 'Lingua',
      role: 'Ruolo',
      languages: {
        es: 'Spagnolo',
        en: 'Inglese',
        it: 'Italiano',
      },
      roles: {
        admin: 'Amministratore',
        user: 'Utente',
        mentor: 'Mentore',
      },
      profile: 'Profilo',
      numericFormat: 'Formato numerico',
      numericFormats: {
        decimalsComma: 'Migliaia separate da punto, decimali separate da virgola (1.000.000,00)',
        decimalsPeriod: 'Migliaia separate da virgola, decimali separate da punto (1.000.000,00)',
      },
    },
    table: {
      search: 'Cerca',
      filters: 'Filtri',
      noResults: 'Nessun risultato trovato',
      cleanFilters: 'Pulisci',
      applyFilters: 'Applica filtri',
      actions: 'Azioni',
      rowsAction: 'Azioni righe',
      selectPage: 'Seleziona pagina',
      selectAll: 'Seleziona tutto',
      unselectAll: 'Deseleziona tutto',
      showingTableResults: 'Mostrando %FIRST% al %LAST% di %TOTAL% risultati',
      goTo: 'Vai a',
      configColumns: 'Configura colonne',
      columns: 'Colonne',
      edited: 'Modificato',
      confirm: 'Conferma',
      reset: 'Resetta',
      errorFetchingGeneric: 'Si è verificato un errore durante il recupero dei dati',
      errorFetchingUnauthorized: 'Non hai i permessi per accedere ai dati',
    },
    admin: {
      platform: {
        title: 'Impostazioni della piattaforma',
        generals: 'Generali',
        email: 'Email',
        forms: 'Moduli',
        legal: 'Legale',
        id: 'ID',
        text: 'Titolo',
        type: 'Tipo',
        mandatory: 'Obbligatorio',
        afirmative: 'Sì',
        negative: 'No',
        answers: 'Risposte',
        startupCoreQuestions: 'Domande principali di una startup',
        mentorCoreQuestions: 'Domande principali di un mentore',
        fundCoreQuestions: 'Domande principali di un fondo',
        types: {
          text: 'Testo',
          textarea: 'Area di testo',
          select: 'Seleziona',
          radiobutton: 'Opzione singola',
          checkbox: 'Opzione multipla',
          list: 'Lista',
          document: 'Documento',
          date: 'Data',
          number: 'Numero',
        },
        lang: 'Lingua',
        order: 'Ordine',
        answer: 'Risposta',
        public: 'Pubblico',
        filter: 'Filtro',
        theField: 'Il campo',
        theAnswer: 'La risposta',
        inLanguage: 'nella lingua',
        isEmpty: 'è vuoto',
        confirmSave: 'Sei sicuro di voler salvare la domanda?',
        emails: {
          fromName: 'Nome mittente',
          fromAdress: 'Indirizzo email mittente',
          mainTemplate: 'Modello principale',
          supportAddress: 'Email di ricezione per il modulo di supporto',
          servicesAddress: 'Email di ricezione per le richieste di servizio',
          webhooksAddress: 'Email di ricezione per i webhook',
          emailBot: 'Bot email',
          emailBotPassword: 'Password del bot email',
          subject: 'Oggetto',
          sender: 'Mittente',
          body: 'Corpo',
          languages: {
            es: 'Spagnolo',
            en: 'Inglese',
            it: 'Italiano',
          },
          templates: {
            inviteToStartupExist: {
              title: 'Invito utente a startup (utente già registrato)',
              description: '',
            },
            inviteToStartupNew: {
              title: 'Invito utente a startup',
              description: '',
            },
            mentoringAccepted: {
              title: 'Mentorato accettato',
              description: '',
            },
            mentoringDocument: {
              title: 'Nuovo documento in un mentorato',
              description: '',
            },
            mentoringFinished: {
              title: 'Mentorato completato',
              description: '',
            },
            mentoringInviteMentor: {
              title: 'Invito a mentorato (startup al mentore)',
              description: '',
            },
            mentoringInviteStartup: {
              title: 'Invito a mentorato (mentore alla startup)',
              description: '',
            },
            mentoringMeeting: {
              title: 'Nuovo evento in un mentorato',
              description: '',
            },
            mentoringMeetingDelete: {
              title: 'Cancellazione evento in un mentorato',
              description: '',
            },
            mentoringMeetingUpdate: {
              title: 'Aggiornamento evento in un mentorato',
              description: '',
            },
            mentoringMessage: {
              title: 'Nuovo messaggio in un mentorato',
              description: '',
            },
            mentoringRejected: {
              title: 'Mentorato respinto',
              description: '',
            },
            notificationsSummary: {
              title: 'Avviso notifiche non lette',
              description: '',
            },
            passwordChanged: {
              title: 'Password modificata',
              description: '',
            },
            recoveryPassword: {
              title: 'Recupero password',
              description: '',
            },
            welcomeAdmin: {
              title: 'Benvenuto amministratore',
              description: '',
            },
            welcomeMentor: {
              title: 'Benvenuto mentore',
              description: '',
            },
            welcomeUser: {
              title: 'Benvenuto utente',
              description: '',
            },
            secondFactor: {
              title: 'Secondo fattore di autenticazione',
              description: '',
            },
            fundraiseInvite: {
              title: 'Invito a raccogliere fondi',
              description: '',
            },
            startupAccessRequest: {
              title: 'Richiesta di accesso a startup',
              description: '',
            },
            startupAccessRequestAccepted: {
              title: 'Richiesta di accesso a startup accettata',
              description: '',
            },
            startupAccessRequestRejected: {
              title: 'Richiesta di accesso a startup respinta',
              description: '',
            },
            startupInviteAccepted: {
              title: 'Invito a startup accettato',
              description: '',
            },
            startupInviteRejected: {
              title: 'Invito a startup respinto',
              description: '',
            },
            startupInviteFounder: {
              title: 'Invito al fondatore a gestire',
              description: '',
            },
            newElementPermission: {
              title: 'Nuovo permesso su elemento',
              description: '',
            },
            confirmInitializedCaptable: {
              title: 'Conferma inizializzazione del captable',
              description: '',
            },
          },
          mergetags: {
            CONTENT: 'Questo è il contenuto',
            FIRSTNAME: 'Questo è il nome',
            LASTNAME: 'Questo è il cognome',
            EMAIL: 'Questa è l\'email',
            FROM_FIRSTNAME: 'Questo è il nome del mittente',
            FROM_LASTNAME: 'Questo è il cognome del mittente',
            TOKEN: 'Questo è il token',
            STARTUP_NAME: 'Questo è il nome della startup',
            MENTOR_NAME: 'Questo è il nome del mentore',
            FROM_NAME: 'Questo è il nome del mittente',
            MEETING_TITLE: 'Questo è il titolo dell\'incontro',
            MEETING_DESCRIPTION: 'Questo è la descrizione dell\'incontro',
            MEETING_DATE: 'Questa è la data dell\'incontro',
            MEETING_TIME: 'Questa è l\'ora dell\'incontro',
            MEETING_OLD_DATE: 'Questa è la vecchia data dell\'incontro',
            MEETING_OLD_TIME: 'Questa è la vecchia ora dell\'incontro',
            DOCUMENT_NAME: 'Questo è il nome del documento',
            MESSAGE: 'Questo è il messaggio',
            NOTIFICATIONS: 'Questo è il messaggio delle notifiche',
            FUNDRAISE: 'Questo è il messaggio della raccolta fondi',
            INVITE: 'Questo è il messaggio dell\'invito',
            CAPTABLE_NAME: 'Questo è il nome del captable',
            CAPTABLE_ID: 'Questo è l\'ID del captable',
            STARTUP_ID: 'Questo è l\'ID della startup',
          },
        },
        name: 'Nome',
        logo: 'Logo',
        favicon: 'Favicon',
        bgPublicDesktop: 'Sfondo visualizzazione pubblica (desktop)',
        bgPublicMobile: 'Sfondo visualizzazione pubblica (mobile)',
        mainColor: 'Colore principale',
        googleAnalytics: 'ID di misurazione GA',
        googleAnalyticsProperty: 'ID proprietà GA',
        googleAnalyticsCredentials: 'Credenziali GA (JSON)',
        configSignups: 'Configurazione moduli di registrazione',
        configTools: 'Strumenti attivi',
        tools: {
          capTable: 'Cap Table',
          performance: 'KPIs',
          dataRoom: 'Documenti',
          profile: 'Profilo',
          mentoring: 'Mentoring',
          reporting: 'Reportistica',
          users: 'Gestione utenti',
          messages: 'Messaggi',
          services: 'Servizi',
          fundraise: 'Raccolta fondi',
        },
        jsonLiterals: 'Traduzione letterali JSON (esempio: {"en":{"common":{"add":"Add"}},"it":{"common":{"add":"Aggiungi"}}}',
        signupExternal: 'Registrazione aperta al pubblico',
        signupInternal: 'Creazione di startup dalla prehome',
        save: 'Salva',
        send: 'Invia',
        permissions: {
          current: 'Corrente (Non modificare)',
          noPermission: 'Nessun permesso',
          see: 'Vedi',
          edit: 'Modifica',
          manage: 'Gestisci',
        },
        owner: 'Proprietario',
        user: 'Utente',
        shareholder: 'Investitore',
        log: 'Log',
        seeLogs: 'Vedi i log',
        botEmails: 'Email ricevute dal bot',
        emailDetails: 'Dettagli dell\'email',
        signaturitKey: 'Chiave di Signaturit',
        roles: {
          owner: 'Proprietari',
          admin: 'Amministratori',
          user: 'Utenti',
          shareholder: 'Investitori',
          startup: 'Tutti gli utenti',
          other: 'Altro',
        },
      },
      analytics: {
        views: 'Visualizzazioni',
        sessions: 'Sessioni',
        users: 'Utenti',
        averageSession: 'Sessione media',
        chartsPerDay: 'Grafici al giorno',
        mostVisitedStartups: 'Startups più visitate',
        mostVisitedPages: 'Pagine più visitate',
        mostUsedTools: 'Strumenti più utilizzati',
        mostActiveUsers: 'Utenti più attivi',
        startup: 'Startup',
        tool: 'Strumento',
      },
      support: {
        title: 'Commenti di supporto',
        firstname: 'Nome',
        lastname: 'Cognome',
        message: 'Messaggio',
        date: 'Data',
        question: 'Domanda',
        user: 'Utente',
      },
      scripts: {
        title: 'Registro degli script',
        cronLogs: 'Registro degli Compiti Cron',
        method: 'Metodo',
        input: 'Input',
        output: 'Output',
        runtime: 'Tempo di esecuzione',
        scriptDetail: 'Dettagli dello script',
      },
    },
    startupUsers: {
      userDatabase: 'Database utenti',
      inviteUser: 'Invita membro',
      email: 'Email',
      name: 'Nome',
      lastname: 'Cognome',
      role: 'Ruolo',
      avatar: 'Avatar',
      type: 'Tipo',
      permissions: 'Permessi',
      permissionsByType: 'Permessi per tipo di utente',
      capTable: 'Cap Table',
      performance: 'KPIs',
      dataRoom: '',
      profile: 'Profilo',
      mentoring: 'Mentorato',
      reporting: 'Report',
      users: 'Utenti',
      admin: 'Amministratore',
      user: 'Utente',
      shareholder: 'Investitore',
      groups: 'Gruppi',
      messages: 'Messaggi',
      noPermission: 'Nessun permesso',
      none: 'Nessuno',
      errorEmptyPerms: 'Non hai selezionato nessun permesso',
      see: 'Visualizza',
      edit: 'Modifica',
      manage: 'Gestisci',
      lang: 'Lingua',
      types: {
        owner: 'Proprietario',
        admin: 'Amministratore',
        user: 'Utente',
        shareholder: 'Investitore',
        ESOPs: 'ESOPs',
        other: 'Altro',
      },
      languages: {
        es: 'Spagnolo',
        en: 'Inglese',
        it: 'Italiano',
      },
      inviteUserSuccess: 'Utente invitato correttamente, presto riceverà una email per completare l\'invito. Puoi vedere lo stato nella scheda pendenti e una volta accettato ti notifieremo.',
      acceptUserSuccess: 'Utente accettato correttamente',
      removeUser: 'Rimuovi Utente',
      questionRemoveUser: 'Sei sicuro di voler rimuovere l\'utente?',
      questionCancelInviteUser: 'Sei sicuro di voler annullare l\'invito?',
      questionRejectUser: 'Sei sicuro di voler rifiutare la richiesta dell\'utente?',
      editPermissions: 'Modifica permessi',
      leaveStartup: 'Abbandona startup',
      leaveStartupQuestion: 'Sei sicuro di voler abbandonare la startup?',
      remove: 'Rimuovi',
      send: 'Invia',
      pending: 'In attesa',
      guests: 'Ospiti',
      admins: 'Amministratori',
      shareholders: 'Investitori',
      pendings: 'In attesa',
      invite: 'Invito',
      request: 'Richiesta',
      confirmAccept: 'Sei sicuro di voler accettare l\'invito?',
      confirmReject: 'Sei sicuro di voler rifiutare l\'invito?',
      confirm: 'Conferma',
      user_already_exist: 'Questo utente fa già parte della startup',
    },
    adminUsers: {
      createUser: 'Crea utente',
      users: 'Utenti',
      invite: 'Invita',
      send: 'Invia',
      avatar: 'Avatar',
      fullname: 'Nome completo',
      email: 'Email',
      role: 'Ruolo',
      date: 'Data',
      group: 'Gruppo',
      lastAccess: 'Ultimo accesso',
      lastModification: 'Ultima modifica',
      extra: 'Altre informazioni (JSON)',
      view: 'Visualizza',
      delete: 'Elimina',
      exportExcel: 'Scarica modello Excel',
      andUpload: 'e caricalo',
      successCreate: 'Sono stati creati correttamente %SUCCESS% utenti',
      successCreateOne: 'È stato creato correttamente %SUCCESS% utente',
      row: 'Riga',
      rowErrors: 'Errori nelle seguenti righe',
      requiredField: 'Il campo %FIELD% è obbligatorio',
      existUserErr: 'L\'utente esiste già',
    },
    adminStartups: {
      startups: 'Startups',
      startupName: 'Nome della startup',
      group: 'Gruppo',
      description: 'Descrizione',
      name: 'Nome',
      lastname: 'Cognome',
      email: 'Email',
      language: 'Lingua',
      exportExcel: 'Scarica modello Excel',
      andUpload: 'e caricalo',
      validateDuplicatesName: 'Cosa fare con i duplicati?',
      nothing: 'Niente',
      edit: 'Modifica',
      duplicate: 'Duplica',
      sendEmail: 'Invia email',
      send: 'Invia',
      no: 'No',
      yes: 'Sì',
      invite: 'Invita',
      successCreate: 'Gli utenti sono stati creati correttamente %SUCCESS%',
      successCreateOne: 'L\'utente è stato creato correttamente %SUCCESS%',
      row: 'Riga',
      rowErrors: 'Errori nelle seguenti righe',
      emptyName: 'Il campo NOME è obbligatorio',
      verified: 'Verificati',
      phantoms: 'Fantasmi',
      markAsPhantom: 'Contrassegna come fantasma',
      unmarkAsPhantom: 'Deseleziona come fantasma',
      mergeStartups: 'Unisci startup',
      mergeStartupsInfo: 'Seleziona la startup principale',
      confirmMergeStartups: 'Sei sicuro di voler unire queste %STARTUPS% startup nella startup %STARTUP%?',
      startupPlan: {
        choosePlan: 'Scegli piano',
        chooseStartupPlan: 'Scegli un piano per la startup',
        free: 'Gratuito',
        freeDescription: 'Piano gratuito',
        basic: 'Base',
        basicDescription: 'Piano base',
        premium: 'Premium',
        premiumDescription: 'Piano premium',
      },
      startupVerify: {
        verify: 'Verifica',
        verified: 'Verificato',
        editVerified: 'Modifica verificato',
        chooseStartupVerify: 'Scegli il grado di verifica per la startup',
        notVerified: 'Non verificato',
        notVerifiedDescription: 'La startup non è verificata',
        team: 'Team',
        full: 'Completo',
      },
    },
    help: {
      help: 'Aiuto',
      home: 'Home',
      submit: 'Invia',
      cancel: 'Annulla',
      form: 'Lascia un messaggio',
      sent: 'Inviato',
    },
    reporting: {
      title: 'Report',
      newReport: 'Nuovo report',
      reportDetail: 'Dettaglio report',
      add: 'Aggiungi',
      subject: 'Oggetto',
      recipients: 'Destinatari',
      message: 'Messaggio',
      attachDocuments: 'Allega documenti',
      attached: 'Documenti allegati',
      uploadedDate: 'Data caricamento',
      uploadedBy: 'Caricato da',
      details: 'Dettagli',
      emails: 'Email',
      search: 'Cerca',
      upload: 'Carica',
      lastName: 'Cognome',
      preview: 'Anteprima',
      sendTest: 'Invia test',
      test: 'Test',
      testSent: 'Report di prova inviato',
      noAttachments: 'Nessun documento allegato nel testo',
      lastReports: 'Ultimi report',
      noReports: 'Non sono stati inviati ancora report',
      templates: 'Template',
      template: 'Template',
      newTemplate: 'Crea template',
      editTemplate: 'Modifica template',
      blankTemplate: 'Template vuoto',
      noImage: 'Nessuna immagine',
      language: 'Lingua',
      questionDeleteTemplate: 'Sei sicuro di voler cancellare il template?',
      fieldEmpty: 'Il campo è vuoto',
      draft: 'Bozza',
      documents: 'Documenti',
      email: 'Email',
      document: 'Documento',
      downloadPdf: 'Scarica PDF',
      reportingNotFound: 'Il report non esiste',
      reportTemplateNotFound: 'Il template del report non è stato trovato',
      name: 'Nome',
      content: 'Contenuto',
      save: 'Salva',
      or: 'o',
      scope: 'Ambito',
      owner: 'Proprietario',
    },
    services: {
      title: 'Servizi',
      requests: 'Richieste',
      service: 'Servizio',
      request: 'Richiesta',
      createService: 'Crea servizio',
      editService: 'Modifica servizio',
      createRequest: 'Crea richiesta',
      editRequest: 'Modifica richiesta',
      serviceInfo: 'Informazioni sul servizio',
      company: 'Azienda',
      shortDescription: 'Descrizione breve',
      detailedDescription: 'Descrizione dettagliata',
      questionRemoveService: 'Sei sicuro di voler eliminare il servizio?',
      questionRemoveRequest: 'Sei sicuro di voler eliminare la richiesta?',
      questionContactManager: 'Desideri che un gestore ti contatti?',
      confirmAnswer: 'Grazie, ti contatteremo a breve',
      cancelRequestAnswer: 'La richiesta è stata cancellata correttamente',
      visible: 'Visibile',
      status: 'Stato',
      lead: 'Potenziale cliente',
      appointment: 'Appuntamento',
      pending: 'In sospeso',
      rejected: 'Rifiutato',
      done: 'Completato',
      requestInfo: 'Richiedi informazioni',
      creationDate: 'Data di creazione',
      internalNotes: 'Note interne (non visibili al cliente)',
      details: 'Dettagli',
      message: 'Messaggio',
      internalService: 'Servizio interno',
      externalService: 'Servizio esterno',
      import_captable: 'Importa captable',
      upload_captable: 'Carica captable',
      request_startup_access: 'Richiesta di accesso alla startup',
    },
    webhooks: {
      title: 'Webhook',
      captable_operation: 'Operazione di captable',
      sent: 'Inviato',
      success: 'Completato',
      error: 'Errore',
      sentTo: 'Inviato a',
      webhookDetails: 'Dettagli del webhook',
      data: 'Dati',
      response: 'Risposta',
    },
    myPosition: {
      title: 'La mia posizione',
      shares: 'Azioni',
      debts: 'Debiti',
      options: 'Opzioni',
      value: 'Valore',
      ownership: 'Proprietà',
      fullyDiluted: 'Completamente diluito',
      entryDate: 'Data di ingresso',
      entryValuation: 'Valutazione di ingresso',
      numeration: 'Numerazione',
      capital: 'Capitale',
      principal: 'Capitale',
      pending: 'In sospeso',
      interest: 'Interessi',
      from: 'Da',
      to: 'A',
      number: 'Numero',
      assigned: 'Assegnate',
      vested: 'Acquisite',
      exercised: 'Esercitato',
      noParticipation: 'L\'investitore non ha partecipazione nel captable',
      shareholder: 'Azionista',
      shareholders: 'Azionisti',
      investmentEvolution: 'Evoluzione dell\'investimento',
      debtsEvolution: 'Evoluzione dei debiti',
      optionsEvolution: 'Evoluzione delle opzioni',
      operation: 'Operazione',
      shareClass: 'Classe di azioni',
      contributedCapital: 'Capitale contribuito',
      pps: 'Prezzo per azione',
      vote: 'Voto',
      status: {
        status: 'Stato',
        pending: 'In sospeso',
        closed: 'Chiuso',
      },
      accrualFrequency: 'Frequenza di accumulo',
      startDate: 'Data di inizio',
      expirationDate: 'Data di scadenza',
      capitalized: 'Capitalizzato',
      repaid: 'Rimborsato',
      interestRate: 'Tasso di interesse',
      exercisePrice: 'Prezzo di esercizio',
      ratio: 'Ratio di conversione',
      vestingPlan: 'Piano di acquisizione',
      shareDetails: 'Dettagli azione',
      debtDetails: 'Dettagli debito',
      optionDetails: 'Dettagli opzione',
      numberOfShares: 'Numero di azioni',
      myShares: 'Mie azioni',
      myDebts: 'Miei debiti',
      myOptions: 'Mie opzioni',
      convertibles: 'Convertibili',
      totalValue: 'Valore totale',
      latestCompanyValuation: "Ultima valutazione dell'azienda",
      totalPotentialValue: 'Valore potenziale totale',
      positionOverview: 'Panoramica della posizione',
      totalInvested: 'Totale investito',
      multiplo: 'Multiplo',
      postMoney: 'Valutazione post-money',
      valuation: 'Valutazione',
      equityGrowth: 'Crescita del capitale proprio',
      vestedOptions: 'Opzioni acquisite',
      pendingOptions: 'Opzioni in sospeso',
      totalOptions: 'Opzioni totali',
      milestones: 'Obiettivi',
      calendar: 'Calendario',
      simulation: 'Simulazione',
    },
    emailStats: {
      email: 'Email',
      status: 'Stato',
      registered: 'Registrato',
      processed: 'Elaborato',
      delivered: 'Consegnato',
      open: 'Aperto',
      click: 'Cliccato',
      error: 'Errore',
      reporting: 'Report',
      attached: 'Allegato',
      sendingDate: 'Data di invio',
      statusInfo: 'Informazioni sullo stato',
      timeline: 'Cronologia',
      errorReason: 'Motivo dell\'errore',
      link: 'Link',
      response: 'Risposta',
    },
    dataRoom: {
      dataRoom: 'Documenti',
      download: 'Scarica',
      open: 'Apri',
      root: 'Radice',
      file: 'File',
      files: 'File',
      date: 'Data',
      creator: 'Creatore',
      viewers: 'Visualizzatori',
      seenBy: 'Visto da',
      see: 'Visualizza',
      avatar: 'Avatar',
      user: 'Utente',
      rename: 'Rinomina',
      upload: 'Carica',
      fileName: 'Nome file',
      fileDescription: 'Descrizione file',
      link: 'Link',
      selectFile: 'Seleziona un file',
      uploadNewVersion: 'Carica nuova versione',
      versions: 'Versioni',
      previousVersions: 'Versioni precedenti',
      noPrevVersions: 'Nessuna versione precedente disponibile',
      actualVersion: 'Versione attuale',
      createFolder: 'Crea cartella',
      createNewFolder: 'Crea nuova cartella',
      renameFolder: 'Rinomina cartella',
      editFolder: 'Modifica cartella',
      folderName: 'Nome cartella',
      emptyFolder: 'Nessun contenuto presente in questa cartella',
      noFolderPermissions: 'Non hai i permessi per visualizzare questa cartella',
      emptyBookmarks: 'Nessun contenuto salvato come preferito',
      noFolderSelected: 'Nessuna cartella selezionata',
      folders: 'Cartelle',
      folder: 'Cartella',
      content: 'Contenuto',
      viewed: 'Visualizzato',
      title: 'Titolo',
      description: 'Descrizione',
      createdBy: 'Creato da',
      updatesBy: 'Aggiornato da',
      createdAt: 'Creato il',
      updatedAt: 'Aggiornato il',
      infoFile: 'Informazioni file',
      fileViews: 'Visualizzazioni file',
      details: 'Dettagli',
      views: 'Visualizzazioni',
      move: 'Sposta',
      error: 'Si è verificato un errore',
      noPermissions: 'Non hai i permessi per eseguire questa azione',
      dropInside: 'Rilascia all\'interno',
      folderNotEditable: 'Questa cartella non è modificabile',
      cantEditDefaultFolders: 'Non è possibile modificare le cartelle predefinite',
      defaultFolders: {
        captable: 'Captable',
        captable_operations: 'Operazioni captable',
        captable_shareholders: 'Azionisti captable',
        reporting: 'Report',
        fundraise: 'Raccolta fondi',
      },
      search: 'Cerca',
      actual: 'Attuale',
      version: 'Versione',
      uploadedBy: 'Caricato da',
      uploadedAt: 'Caricato il',
      recents: 'Recenti',
      bookmarks: 'Segnalibri',
      today: 'Oggi',
      lastWeek: 'Ultima settimana',
      lastMonth: 'Ultimo mese',
      previousContent: 'Contenuto precedente',
      confirmDeleteFolder: 'Sei sicuro di voler eliminare questa cartella? Verranno cancellate tutte le sottocartelle e i documenti al suo interno',
    },
    footer: {
      contact: 'Contatto',
      privacy: 'Informativa sulla privacy',
      cookies: 'Informativa sui cookie',
      terms: 'Termini e condizioni',
    },
    breadcrumb: {
      home: 'Home',
      goBack: 'Torna indietro',
      startup: 'Startup',
      mentorings: 'Mentorship',
      editAccount: 'Modifica Account',
      search: 'Cerca',
      users: 'Utenti',
    },
    mentoringCard: {
      nextMeeting: 'Prossima riunione',
      noNextMeeting: 'Non pianificato',
      lastInteraction: 'Ultima interazione',
      founderIn: 'Fondatore in',
    },
    performance: {
      add: 'Aggiungi',
      addKpi: 'Aggiungi KPI',
      title: 'Performance',
      kpi: 'KPI',
      kpis: 'KPI',
      startupKpis: 'KPIs della startup',
      userKpis: 'KPIs creati da te in altre startup',
      templates: 'Modelli',
      createKpi: 'Crea KPI',
      createTemplate: 'Crea Modello',
      category: 'Categoria',
      periodicity: 'Periodicità',
      positive: 'Positivo',
      scope: 'Ambito',
      unit: 'Unità',
      minMax: 'Min|Max',
      notes: 'Note',
      questionDeleteKpi: 'Sei sicuro di voler eliminare il KPI?',
      questionDeleteTemplate: 'Sei sicuro di voler eliminare il modello?',
      questionUnlinkKpi: 'Sei sicuro di voler rimuovere il KPI?',
      questionDeleteKpiValue: 'Sei sicuro di voler eliminare il valore?',
      questionConfirmChangeTable: 'Sei sicuro di voler cambiare tabella? Perderai i valori non salvati.',
      questionScopeToAll: "Sei sicuro di voler cambiare l'ambito del KPI a globale? Il proprietario perderà i permessi di modifica.",
      changeScopeToAll: 'Cambia a ambito globale',
      daily: 'Giornaliero',
      weekly: 'Settimanale',
      biweekly: 'Bisettimanale',
      monthly: 'Mensile',
      quarterly: 'Trimestrale',
      biannual: 'Semestrale',
      annual: 'Annuale',
      business: 'Business',
      financial: 'Finanziario',
      esg: 'ESG',
      integer: 'Interi',
      float: 'Decimali',
      currency: 'Valuta',
      percent: 'Percentuale',
      up: 'Aumento',
      down: 'Diminuzione',
      equal: 'Stabile',
      min: 'Minimo',
      max: 'Massimo',
      infinitePositive: '+∞',
      infiniteNegative: '-∞',
      custom: 'Personalizzato',
      fill: 'Compila',
      noKpis: 'Nessun KPI',
      addKpis: 'Aggiungi KPI',
      orderKpis: 'Ordina KPI',
      fillValues: 'Compila valori',
      viewValues: 'Visualizza valori',
      addNewValue: 'Aggiungi nuovo valore',
      configuration: 'Configurazione',
      autoSave: 'Salvataggio automatico',
      saving: 'Salvataggio in corso...',
      withoutChanges: 'Senza modifiche',
      dataIncomplete: 'Campi con * sono incompleti',
      previous: 'Precedente',
      date: 'Data',
      lastUpdate: 'Ultimo aggiornamento',
      target: 'Obiettivo',
      diffTarget: 'Differenza obiettivo',
      value: 'Valore',
      values: 'Valori',
      diffValue: 'Differenza valore',
      questionSetTarget: 'Vuoi impostare l\'obiettivo del KPI?',
      questionSetTargetMultiple: 'Vuoi impostare l\'obiettivo di ogni KPI?',
      selectTemplate: 'Seleziona un modello',
      alreadyAdded: 'Già aggiunto',
      noResultsFill: 'Nessun risultato per compilare',
      noResults: 'Nessun risultato',
      noKPIs: 'Nessun KPI aggiunto ancora',
      lastValue: 'Ultimo valore',
      newValue: 'Nuovo valore',
      pendingKpis: 'Ci sono KPI non aggiornati',
      pending: 'In sospeso',
      noPending: 'Congratulazioni, non hai KPI in sospeso da aggiornare!',
      updated: 'Aggiornato',
      bookmark: 'Segnalibro',
      unbookmark: 'Rimuovi segnalibro',
      selectKpi: 'Clicca qui per selezionare il KPI',
      select: 'Seleziona',
      kpiValue: 'Valore del KPI',
      editorPlaceholder: 'Premi TAB per inserire',
      order: 'Ordine',
      mode: 'Modalità',
      chart: 'Grafico',
      table: 'Tabella',
      comparator: 'Comparatore',
      emptyKpis: 'Seleziona i KPI da confrontare',
      downloadExcel: 'Scarica Excel',
      uploadExcel: 'Carica Excel',
      actions: 'Azioni',
      confirmChanges: 'Sei sicuro di voler applicare le modifiche?',
      successChanges: 'Modifiche applicate con successo',
      errorChanges: 'Errore durante l\'applicazione delle modifiche',
      newValues: 'Nuovi valori',
      editedValues: 'Valori modificati',
      deletedValues: 'Valori eliminati',
      exportExcel: 'Esporta Excel',
      uploadChanges: 'e caricalo con le modifiche desiderate',
      excelErrors: 'Sono stati rilevati i seguenti errori nel file Excel, correggili e carica nuovamente il file',
      excelConfirm: 'Di seguito sono elencate le modifiche rilevate nel file Excel, rivedile e conferma il caricamento',
      kpiValueErr: 'Il KPI %KPI% %ERROR% nella data %DATE%',
      errMax: 'ha un valore superiore al massimo',
      errMin: 'ha un valore inferiore al minimo',
      errInteger: 'deve essere un numero intero',
      errNumber: 'deve essere un numero',
      errKpi: 'Riga %ROW%, il KPI %KPI% non esiste',
      back: 'Indietro',
      editKpi: 'Modifica KPI',
      ajustValues: 'I seguenti valori del KPI devono essere aggiustati',
      errors: {
        value_less_than_min: 'saranno regolati al minimo',
        value_greater_than_max: 'saranno regolati al massimo',
        value_must_be_an_integer: 'saranno convertiti in un numero intero',
        invalid_date_format: 'saranno spostati alla data successiva se vuoti o rimossi',
      },
      kpiRelations: 'Relazioni KPI',
      relationValues: 'Storico valori di relazione',
      forecast: 'Previsione',
      addNotes: 'Aggiungi note',
      removeNotes: 'Rimuovi note',
      updateKpiValue: 'Aggiorna valore KPI',
      showValues: 'Mostra valori',
      hideValues: 'Nascondi valori',
      showForecast: 'Mostra previsione',
      hideForecast: 'Nascondi previsione',
      card: 'Carta',
      representKpi: 'Come vuoi rappresentarlo?',
      selectAKpi: 'Seleziona un KPI',
      aggregate: 'Aggregato',
      endOfPeriod: 'Fine del periodo',
      accumulative: 'Cumulativo',
      average: 'Media',
      eop: 'FDP',
      acc: 'ACC',
      avg: 'MED',
      emailBot: {
        emailBot: 'Bot email',
        updateValues: 'Aggiorna valori tramite bot email',
        updateValuesQuestion: 'Come posso aggiornare i valori dei miei KPI tramite il bot email?',
        updateValuesDesc: 'Puoi inviare un\'email contenente le seguenti informazioni',
        address: 'Indirizzo',
        subject: 'Oggetto: Aggiornamento KPI',
        attachments: 'Allegati: Modello compilato con i valori da aggiungere o aggiornare',
        downloadTemplate: 'Scarica modello',
        requirement: 'Nota: L\'email deve essere inviata da un account con permessi di modifica nell\'app "Performance"',
      },
      permissions: 'Permessi',
      lessDateErr: 'La data di fine deve essere successiva alla data di inizio',
      diffRangeErr: 'Il range di mesi confrontati deve essere lo stesso del range di base',
      owner: 'Proprietario',
    },
    messages: {
      yourMessage: 'Scrivi il tuo messaggio',
      emptyChat: 'Nessun messaggio',
      sendMessage: 'Invia',
      readMore: 'Leggi di più',
      readLess: 'Leggi di meno',
      loadMore: 'Carica altro',
      loading: 'Caricamento...',
      answer: 'Rispondi',
      reply: 'Risposta',
      replies: 'Risposte',
      comment: 'Commenta',
      cancel: 'Annulla',
      showReplies: 'Mostra risposte',
      hideReplies: 'Nascondi risposte',
      unread: 'Non letto',
      unreads: 'Non letti',
      uploadError: 'Errore durante il caricamento del file',
      attachFile: 'Allega file',
      days: 'giorni',
      hours: 'ore',
      minutes: 'min',
      seconds: 'sec',
      justNow: 'adesso',
      new: 'nuovo',
      close: 'Chiudi',
    },
    messagesMenu: {
      startupWall: 'Muro dei messaggi',
      startupWallDescription: 'Comunicazioni private della startup',
    },
    captable: {
      title: 'Tabella dei partecipanti',
      captables: 'Tabelle dei partecipanti',
      createCaptable: 'Crea tabella dei partecipanti',
      editCaptable: 'Modifica tabella dei partecipanti',
      setupCaptable: 'Configura la tua tabella dei partecipanti',
      operations: 'Operazioni',
      operation: 'Operazione',
      shares: 'Azioni',
      debts: 'Debiti',
      options: 'Opzioni',
      convertibles: 'Convertibili',
      shareClasses: 'Classi di azioni',
      capital: 'Capitale',
      contributedCapital: 'Capitale versato',
      ownership: 'Proprietà',
      fullyDiluted: 'Diluito al massimo',
      fullyDilutedAcronym: 'DMA',
      secondary: 'Secondario',
      secondarySale: 'Vendita secondaria',
      changeClasses: 'Cambia classi',
      reduce: 'Riduci',
      updateValuation: 'Aggiorna la valutazione della società',
      newOperation: 'Nuova operazione',
      emptyCaptable: 'Nessuna operazione nella tabella dei partecipanti',
      noSharesOperation: 'Nessuna operazione con azioni nella tabella dei partecipanti',
      createOperation: 'Crea operazione',
      createFirstOperation: 'Crea prima operazione',
      publishSomeOperation: 'Pubblica qualche operazione',
      initCaptable: 'Inizializza tabella dei partecipanti',
      operationName: 'Nome dell\'operazione',
      editOperation: 'Modifica operazione',
      pending: 'In sospeso',
      vested: 'Acquisite',
      vest: 'Vesti',
      exercised: 'Esercitato',
      issueDate: 'Data di emissione',
      captable: 'Tabella dei partecipanti',
      questionDelete: 'Sei sicuro?',
      issueDebt: 'emette debito',
      receives: 'riceve',
      fromSomeone: 'da',
      toSomeone: 'a',
      change: 'cambia',
      forValueOf: 'Per un valore di',
      madeAValuationOf: 'ha fatto una valutazione di',
      hasBeenA: 'C\'è stata una',
      paid: 'Pagato',
      payoutTimeline: 'Pagati %total% (%amount% debito + %interests% interessi) del debito %debt%',
      for: 'per',
      of: 'di',
      official: 'Ufficiale',
      favorite: 'Preferito',
      valuationDiluted: 'Valutazione diluita',
      officialConfirm: 'Sei sicuro di voler rendere questa tabella dei partecipanti ufficiale?',
      favoriteConfirm: 'Sei sicuro di voler rendere questa tabella dei partecipanti preferita?',
      deleteCaptableConfirm: 'Sei sicuro di voler eliminare questa tabella dei partecipanti?',
      noCaptables: 'Nessuna tabella dei partecipanti',
      issue: {
        shares: 'Emissione azioni',
        sharesHelp: 'Emetti nuove azioni per la società',
        secondaries: 'Secondarie',
        secondariesHelp: 'Vendita di azioni tra investitori',
        changeClasses: 'Cambio classi',
        changeClassesHelp: 'Cambia la classe delle azioni di un investitore',
        reduce: 'Riduzione partecipazioni',
        reduceHelp: 'Riduci le partecipazioni di un investitore nella società',
        stockSplit: 'Divisione azioni',
        stockSplitHelp: 'Dividi le azioni per aumentare il numero totale',
        valuation: 'Valutazione',
        valuationHelp: 'Registra una valutazione della società',
        debt: 'Emissione debito',
        debtHelp: 'Emetti nuovi convertibili per la società',
        convertDebt: 'Converti debito',
        convertDebtHelp: 'Converti debito pendente in azioni',
        payout: 'Pagamento debito',
        payoutHelp: 'Registra il pagamento parziale o totale di un debito',
        options: 'Emissione opzioni',
        issueAssignOptions: 'Emetti nuove opzioni o assegna quelle pendenti',
        optionsHelp: 'Emetti un nuovo set di opzioni per la società',
        vestOptions: 'Assegna opzioni',
        vestOptionsHelp: 'Assegna opzioni assegnate a un investitore',
        exerciseOptions: 'Esercizio opzioni',
        exerciseOptionsHelp: 'Esercita opzioni in azioni della società',
        dividend: 'Distribuzione dividendi',
        dividendHelp: 'Distribuzione dei dividendi tra gli investitori',
        operationName: 'Nome dell\'operazione',
      },
      lastOperation: 'Ultima operazione',
      lastConfirmed: 'Ultima conferma',
      referenceOperationInfo: "L'operazione di riferimento viene utilizzata nelle operazioni per definire i parametri di partenza. Ad esempio, il numero massimo di azioni che ogni investitore può vendere in un'operazione secondaria o il numero massimo da cui iniziare la distribuzione. Questo verrà aggiornato ogni volta che viene selezionata una nuova data, in modo che la piattaforma possa eseguire tutti i controlli necessari per garantire la coerenza dei dati.",
      lastIssue: 'Ultima emissione',
      securities: 'Titoli',
      shareClass: 'Classe azioni',
      shareClassFrom: 'Da classe',
      shareClassTo: 'Cambia a Classe',
      shareCode: 'Codice',
      amount: 'Importo',
      left: 'rimasti',
      pps: 'Prezzo per azione (PPS)',
      repurchasePrice: 'Prezzo di riacquisto',
      numeration: 'Numerazione',
      numerations: 'Numerazioni',
      sharesNumeration: 'Numerazione delle azioni',
      from: 'Da',
      to: 'A',
      shareholders: 'Azionisti',
      issuingShareholder: 'Azionista emittente',
      shareholder: 'Azionista',
      debt: 'Debito',
      selectType: 'Seleziona il tipo',
      selectDebt: 'Seleziona un debito',
      option: 'Opzione',
      selectOption: 'Seleziona un\'opzione',
      selectDebtOrOption: 'Seleziona un debito o un\'opzione',
      convertsTo: 'Convertibile in',
      conversionTriggerAmount: 'Importo di attivazione',
      valuationCap: 'Cap di valutazione',
      earlyExitMultiple: 'Multiplo di uscita anticipata',
      discount: 'Sconto',
      interestRate: 'Tasso di interesse',
      interest: 'Interessi',
      principal: 'Capitale',
      accrualFrequency: 'Frequenza di capitalizzazione',
      phantom: 'Azioni fantasma',
      stockOption: 'Opzioni su azioni',
      stockOptions: 'Opzioni su azioni',
      price: 'Prezzo',
      number: 'Numero',
      newShares: 'Nuove azioni',
      vestingPlan: 'Piano di vesting',
      vestingStart: 'Data di Inizio del Piano',
      vestingPlans: 'Piani di vesting',
      vestedByMilestone: 'Acquisite al raggiungimento di un traguardo',
      vestingTimeline: 'Timeline di vesting',
      startDate: 'Data di inizio',
      expirationDate: 'Data di scadenza',
      errorEmptyName: 'Il campo nome non può essere vuoto',
      errorConfirmOverDraftOperation: 'Non è possibile confermare un\'operazione se non è l\'ultima',
      errorDateBeforeLastOperation: 'Non è possibile confermare un\'operazione se non è l\'ultima',
      confirmSubmit: 'Sei sicuro di confermare l\'operazione?',
      confirmSubmitDraft: 'Sei sicuro di salvare l\'operazione come bozza?',
      saveAsDraft: 'Salva come bozza',
      saveAsSimulation: 'Salva come simulazione',
      confirm: 'Conferma',
      status: 'Stato',
      closed: 'Chiusa',
      draft: 'Bozza',
      confirmed: 'Confermata',
      timeline: 'Timeline',
      totalCashRaised: 'Totale denaro raccolto',
      totalCapitalRaised: 'Totale capitale raccolto',
      capitalRaised: 'Capitale raccolto',
      totalInvestors: 'Totale investitori',
      valuation: 'Valutazione',
      selectValuation: 'Seleziona una valutazione',
      totalValuation: 'Valutazione totale',
      ppsValuation: 'Valutazione PPS',
      ppsLastReport: 'Ultimo rapporto PPS',
      totalDebtOutstanding: 'Debito totale in sospeso',
      outstandingInterest: 'Interessi in sospeso',
      interestAvg: 'Interessi (media)',
      totalDebtHolders: 'Totale debitori',
      outstanding: 'In sospeso',
      capitalized: 'Capitalizzato',
      repaid: 'Rimborsato',
      debtHolders: 'Detentori del debito',
      syndicates: 'Sindacati',
      syndicate: 'Sindacato',
      byShareholder: 'Per azionista',
      byRole: 'Per ruolo',
      bySyndicate: 'Per sindacato',
      byShareClass: 'Per classe azioni',
      noSyndicate: 'Nessun sindacato',
      optionsStatus: 'Stato delle opzioni',
      createOption: 'Crea opzione',
      createShareClass: 'Crea classe di azioni',
      showChart: 'Mostra grafico',
      showTable: 'Mostra tabella',
      ppsEvolution: 'Evoluzione del PPS',
      types: 'Tipi',
      assignOptions: 'Assegna opzioni',
      vestOptions: 'Vesti opzioni',
      errorDeleteConfirmedOperation: 'Non è possibile eliminare un\'operazione confermata se non è l\'ultima',
      pay: 'Paga',
      payouts: 'Pagamenti',
      payout: 'Pagamento',
      secondaries: 'Secondarie',
      capitalize: 'Capitalizza',
      transform: 'Trasforma',
      repaymentsTimeline: 'Timeline dei rimborsi',
      capitalizationsTimeline: 'Timeline delle capitalizzazioni',
      repayDebt: 'Rimborsa debito',
      unassigned: 'Non assegnato',
      errors: {
        notShareholder: 'Azionista non selezionato',
        optionsAssignedLimit: 'Hai assegnato più opzioni di quelle disponibili',
        invalid_number: 'Il numero non è valido',
        invalid_pps: 'Il PPS non è valido',
        invalid_share_class: 'Classe di azioni non selezionata o non esistente',
        invalid_shareholder: 'Azionista non selezionato o non valido',
        invalid_numeration: 'La numerazione delle azioni non è corretta',
        invalid_shares: 'Le azioni non sono valide',
        invalid_option: 'Le opzioni assegnate non esistono',
        invalid_debt: 'Il debito non esiste',
        invalid_vesting_plan: 'Il piano di acquisizione non esiste',
        share_assigned_greather_than_issue: 'Sono state assegnate più azioni di quelle create',
        share_assigned_less_than_issue: 'Sono state assegnate meno azioni di quelle create',
        assign_greather_than_available: 'Sono state assegnate più opzioni di quelle disponibili',
        invalid_principal: 'Il valore del debito non è valido',
        invalid_start_date: 'Data di inizio non selezionata',
        invalid_expiration_date: 'Data di scadenza non selezionata',
        invalid_name: 'Nome non compilato',
        invalid_type: 'Tipo non valido',
        invalid_price: 'Prezzo non valido',
        invalid_amount: 'Quantità non valida',
        payout_amount_greater_than_pending: 'Il pagamento è maggiore del debito pendente',
        invalid_sales_number: 'Il numero di azioni non è valido',
        invalid_sales_shareholder: 'Azionista di vendita non assegnato',
        invalid_sales_numeration: 'La numerazione delle azioni di vendita non è valida',
        sales_numeration_out_of_range: 'La numerazione delle azioni è fuori dal range del venditore',
        invalid_conversion_type: 'Tipo non valido',
        invalid_conversion_id: 'Elemento da convertire non selezionato',
        numeration_not_consecutive: 'La numerazione delle azioni non è consecutiva',
        numeration_out_of_range: 'La numerazione delle azioni è fuori dal range',
        numeration_repeated: 'Numerazione delle azioni ripetuta',
        invalid_source: 'L\'operazione di origine non è l\'ultima operazione confermata',
        initializeCaptable: 'Si è verificato il seguente errore nell\'operazione %OPERATION% per la data %DATE%',
        empty_value: 'La cella è vuota',
        invalid_shareClass: 'Classe di azione non valida',
        origin_not_found: 'Origine non trovata',
        debt_not_found: 'Debito non trovato',
        option_not_found: 'Opzione non trovata',
        invalidRange: 'Intervallo non valido',
        operation_above_with_errors: "L'operazione non può essere confermata perché alcuni dei bozze precedenti hanno errori",
        empty_operation: "Non puoi salvare un'operazione vuota.",
      },
      column: 'Columna',
      celda: 'Cella',
      downloadTheTemplate: 'Descarga la plantilla',
      inicializeCaptableExcelDescription: 'y rellena las operaciones en la hoja correspondiente. Por cada fecha se creará una nueva operacion',
      assignPoolOptions: 'Asignar opciónes existente',
      pool: 'Pool',
      vote: 'Voto',
      votes: 'Votos',
      votingPercentage: 'Porcentaje de votos',
      viewVoting: 'Ver votación',
      votingDetails: 'Detalles de votación',
      shareholdersNumber: 'Shareholders',
      rolesNumber: 'Roles',
      votesNumber: 'Votos',
      information: 'Información',
      notShareholderVotes: 'No hay ningun inversor con derecho a voto',
      value: 'Valor',
      optionsPool: 'Pool de opciones',
      others: 'Otros',
      new: 'Nuevo',
      previousOperation: 'Operación anterior',
      comparator: 'Comparador',
      selectFirstPayoutType: 'Selecciona primero un tipo de pago',
      conversions: 'Conversiones',
      conversion: 'Conversión',
      convert: 'Convertir',
      exerciseStock: 'Ejercer opciones',
      capitalizeDebt: 'Capitalizar deuda',
      noOperations: 'No has agregado ninguna operación',
      addShareholder: 'Añadir inversor',
      automaticNumeration: 'Numeración automatica',
      preMoneyValuation: 'Valutazione alutazione pre-money',
      preMoneyValue: 'Pre-money (valore)',
      prorataDistribution: 'Reparto prorata',
      nominalValue: 'Valor nominal',
      sharePremium: 'Prima de emisión',
      noResults: 'No se han encontrado resultados',
      questionStatusToConfirm: '¿Estas seguro de confirmar la operación?',
      questionStatusToDraft: '¿Estas seguro de cambiar a borrador la operación?',
      questionStatusToDraftMultiple: '¿Estas seguro de cambiar a borrador esta operación y todas las posteriores?',
      errorDraftOperation: 'No se puede cambiar a draft una operación si no es la última',
      errorUndraftOperation: 'No se puede confirmar una operación si tiene errores',
      setAsDraft: 'Cambiar a borrador',
      addTimeline: 'Añadir a la linea temporal',
      removeTimeline: 'Eliminar de la linea temporal',
      cannotConfirmOperation: 'La operación ha sido guardada pero no se puede confirmar debido a que tiene errores, por favor reviselos e intentelo de nuevo',
      saveWithErrors: 'La operación se ha guardado correctamente, pero existen errores que deben solucionarse antes de poder confirmarla',
      canNotSave: 'La operación tiene errores y no puede ser guardada',
      saveAgainToValidateChanges: 'Para actualizar los errores guarde de nuevo la operación',
      operationNotFound: 'La operación no existe o ha sido borrada',
      assigned: 'Asignado',
      optionsToVest: 'Opciones a adquirir',
      exercisePrice: 'Precio de conversión',
      ratio: 'Ratio de conversion',
      stockSplit: 'Stock split',
      multiple: 'Múltiplo',
      liquidation: 'Liquidación',
      exit: 'Salida',
      both: 'Ambos',
      participating: 'Participa',
      moreDetails: 'Más detalles',
      table: 'Tabla',
      chart: 'Grafica',
      distribution: 'Distribución',
      nCurrentShares: 'Número de acciones actuales',
      nNewShares: 'Número de acciones nuevas',
      nCurrentOptions: 'Número de opciones asignadas actuales',
      nNewOptions: 'Número de opciones asignadas nuevas',
      nCurrentUnassignedOptions: 'Número de opciones no asignadas actuales',
      nNewUnassignedOptions: 'Número de opciones no asignadas',
      analyst: 'Analista',
      conflictMessage: 'Se han encontrado varios valores que afectan al PPS de la compañia. Selecciona cuál es la valoración correcta para esta fecha.',
      role: 'Rol',
      founder: 'Fundador',
      director: 'Director',
      boardMember: 'Miembro de la Junta',
      investor: 'Inversor',
      employee: 'Empleado',
      secretary: 'Secretario',
      advisor: 'Consejero',
      bank: 'Banco',
      corporate: 'Corporate',
      angelInvestor: 'Angel investor',
      privateEquity: 'Private equity',
      ventureCapital: 'Venture capital',
      friendsAndFamily: 'Amigos y familiares',
      corporateVentureCapital: 'Corporate venture capital',
      shareDetails: 'Detalles de la acción',
      debtDetails: 'Detalles de la deuda',
      optionDetails: 'Detalles de la opción',
      optionsIn: 'Opciones en',
      additionalDocumentation: 'Documentación adicional',
      totalDividend: 'Dividendo total',
      dividends: 'Dividendos',
      custom: 'Custom',
      voteDistribution: 'Distribución de votos',
      addRow: 'Añadir fila',
      sharesDistribution: 'Distribución de acciones',
      statutoryBook: 'Libro de socios',
      total: 'Total',
      transactions: 'Transacciones',
      simulations: 'Simulaciones',
      waterfall: 'Waterfall',
      deleteWaterfall: '¿Esta seguro de que desea eliminar el waterfall?',
      exitValue: 'Valor de salida',
      debtsToRepay: 'Deudas por pagar',
      preferredShares: 'Acciones preferentes',
      preferred: 'Preferentes',
      common: 'Comunes',
      cap: 'Cap',
      className: 'Clase',
      conversionRatio: 'C.Ratio',
      liquidationMultiple: 'L.Multiplo',
      waterfallToCompare: 'Selecciona los waterfalls a comparar',
      emptyOptionAssign: 'Selecciona una opcion para cada inversor',
      full: 'Detallada',
      totalSummary: 'Resumen del total',
      multipleSummary: 'Resumen del multiplo',
      operationsWillBeDeleted: 'Las operaciones se borrarán',
      savedDataMayHaveErrors: 'Los datos guardados podrian contener errores',
      pendingShares: 'Quedan %SHARES% acciones por repartir',
      saveWaterfallForExcel: 'Para descargar el excel tiene que guardar los cambios primero. ¿Desea guardar el waterfall?',
      deleteErasedItem: 'Esta operacion no existe. ¿Desea eliminarla?',
      certificate: 'Certificado',
      uploadExcelTemplates: 'Subir documentación',
      chooseOption: 'Elige una de las siguientes opciones para comenzar a utilizar la herramienta',
      importFrom: 'Importar desde',
      invitePlatform: 'Invita a %PLATFORM% a tu software de Cap table',
      features: {
        operations: 'Operazioni',
        operationsDescription: 'Emitte azioni, convertibili, gestisce opzioni, vendite di secondari...',
        summary: 'Riepilogo',
        summaryDescription: 'Visualizza in modo semplice chi fa parte del captable',
        timeline: 'Cronologia',
        timelineDescription: 'Accedi in qualsiasi momento agli stati precedenti',
        simulations: 'Simulazioni',
        simulationsDescription: 'Puoi vedere cosa succede in nuovi scenari o quale sarà il waterfall per ognuno di essi',
        export: 'Esporta',
        exportDescription: 'Esporta tutte le informazioni in Excel o genera report per i tuoi investitori in PDF',
        invite: 'Invita',
        inviteDescription: "Concedi l'accesso agli investitori perché possano visualizzare la loro posizione",
      },
      operationNotTheLast: "ATTENZIONE: Questa operazione non è l'ultima",
      confirmMultipleOperations: "Sei sicuro di voler confermare questa operazione e tutte le operazioni precedenti fino all'ultima confermata?",
      uploadExcelDescription: "Allega tutta la documentazione disponibile sul tuo cap table e ci occuperemo di caricare queste informazioni in modo strutturato sulla piattaforma. Includi, se possibile, un file Excel con l'elenco degli attuali azionisti, dettagli sui privilegi delle azioni preferenziali, nonché i contratti dei convertibili e/o delle stock option. Se abbiamo domande, ti contatteremo.",
      additionalDetailsRequest: 'Vuoi fornirci ulteriori dettagli?',
      questionRemoveCaptableRequest: 'Sei sicuro di voler eliminare la richiesta di creazione del cap table?',
      questionRemoveImportCaptableRequest: 'Sei sicuro di voler eliminare la richiesta di importazione del cap table da %PAGE%?',
      markCompleted: 'Contrassegna come completato',
      cancelRequest: 'Annulla richiesta',
      importCaptable: {
        login: 'Accedi al tuo account su %PAGE%',
        navigateCarta: 'Vai a Azienda > Autorizzazioni e Ruoli',
        navigateCapdesk: 'Vai a "Impostazioni" > "Accesso utente"',
        navigateLedgy: 'Fai clic su "Invita collaboratore" sul lato destro della piattaforma',
        addEmail: 'Aggiungi team@startups.land come Amministratore Legale',
        markCompleted: 'Facci sapere di averci aggiunto facendo clic su "Contrassegna come completato"',
        notification: 'Ti faremo sapere quando le tue informazioni saranno pronte su startups.land',
      },
      lead: 'Potenziale cliente',
      appointment: 'Appuntamento',
      rejected: 'Rifiutato',
      done: 'Fatto',
      taxid: 'Codice fiscale',
      editShareholders: 'Modifica gli investitori',
      otherValuesSensitivity: "Altri valori per l'analisi della sensibilità",
      sensitivity: 'Sensibilità',
      noOtherExitValues: 'Non hai inserito altri valori di uscita',
      repay: 'Rimborsare',
      subject: 'Oggetto',
      message: 'Messaggio',
      user: 'Utente',
      uploadDocument: 'Carica documento',
      uploadSignature: 'Carica per firmare',
      signatureStatus: 'Stato della firma',
      uploaded: 'Caricato',
      signed: 'Firmato',
      filterByShareholder: 'Seleziona investitore/i',
      maxCharacters: 'Massimo %NUMBER% caratteri',
      noCaptablePermission: 'Non hai i permessi per visualizzare questo captable',
      other: 'Altro',
      operationTimeout: 'Questa operazione potrebbe richiedere alcuni minuti',
      emailWhenComplete: "Riceverai una email quando l'operazione sarà completata",
    },
    fundraise: {
      title: 'Raccolta fondi',
      info: 'Informazioni',
      investor: 'Investitore',
      investors: 'Investitori',
      amount: 'Importo',
      postMoney: 'Post-Money',
      ownership: 'Proprietà',
      investments: 'Investimenti',
      status: 'Stato',
      round: 'Round',
      investmentRound: 'Round di investimento',
      minTicket: 'Biglietto minimo',
      minTicketPhase: 'Investimento minimo',
      maxTicket: 'Biglietto massimo',
      maxTicketPhase: 'Investimento massimo',
      newRound: 'Nuovo round',
      editRound: 'Modifica round',
      public: 'Pubblico',
      draft: 'Bozza',
      in_progress: 'In corso',
      closed: 'Chiuso',
      viewed: 'Visto',
      sent: 'Inviato',
      invested: 'Investito',
      commitedInvestor: 'Hai un investitore principale impegnato?',
      kpis: 'KPI',
      currentAllocation: 'Assegnazione attuale',
      invest: 'Investi',
      pending: 'In attesa',
      amountToInvest: 'Importo da investire',
      share: 'Condividi',
      unauthorized: 'Non hai i permessi per visualizzare questa raccolta fondi',
      myInvestment: 'Il mio investimento',
      addEmail: 'Aggiungi email',
      invite: 'Invita',
      message: 'Messaggio',
      deleteFundraise: 'Vuoi eliminare questa raccolta fondi?',
      deleteInvestment: 'Vuoi smettere di investire in questa raccolta fondi?',
      deleteInvite: 'Vuoi eliminare questo invito alla raccolta fondi?',
      investmentAmount: 'La tua quantità investita in questo fondo di raccolta fondi è',
      invites: 'Inviti',
      preseed: 'PRE-SEED',
      seed: 'SEED',
      early: 'EARLY',
      growth: 'GROWTH',
      expansion: 'EXPANSION',
      enterToAdd: 'Premi ↵ per aggiungere una nuova email',
      link: 'Link',
      linkDescription: 'Condividi questo link con gli investitori che vuoi che abbiano accesso a investire nel tuo fondo di raccolta fondi. Fai clic sul link per copiarlo negli appunti',
      inviteDescription: "Aggiungi l'email di tutti gli investitori che vuoi che abbiano accesso al tuo fondo di raccolta fondi",
      textCopied: 'Testo copiato!',
      commitment: 'Impegno',
      commitments: 'Impegni',
      addCommitment: 'Aggiungi impegno di investimento',
      whoCanInvest: 'Chi può investire',
      anyoneCanInvest: 'Chiunque può investire',
      byInvitationOnly: 'Solo su invito',
    },
    funds: {
      title: 'Fondi',
      verification: 'Verifica',
      notVerified: 'Non verificato',
      team: 'Squadra',
      full: 'Completo',
      confirmDeleteFund: 'Sei sicuro di voler eliminare questo fondo?',
      downloadExcel: 'Scarica Excel',
      uploadExcel: 'Carica Excel',
      successCreate: 'Sono stati creati correttamente %SUCCESS% fondi',
      successCreateOne: 'È stato creato correttamente %SUCCESS% fondo',
      row: 'Riga',
      rowErrors: 'Errori nelle seguenti righe',
      send: 'Invia',
      exportExcel: 'Scarica il modello',
      andUpload: 'e caricalo',
      emptyName: 'Il campo NOME è obbligatorio',
      emptyCountry: 'Il campo PAESE è obbligatorio',
    },
    notFound: {
      title: 'Pagina non trovata',
      goBack: 'Torna alla home',
    },
    common: {
      all: 'Tutti',
      startup: 'Startup',
      mentor: 'Mentore',
      name: 'Nome',
      description: 'Descrizione',
      document: 'Documento',
      documents: 'Documenti',
      send: 'Invia',
      save: 'Salva',
      add: 'Aggiungi',
      new: 'Nuovo',
      create: 'Crea',
      download: 'Scarica',
      upload: 'Carica',
      added: 'Aggiunto',
      user: 'Utente',
      users: 'Utenti',
      view: 'Visualizza',
      viewMore: 'Mostra di più',
      viewLess: 'Mostra meno',
      duration: 'Durata',
      viewDetail: 'Visualizza dettaglio',
      viewDetails: 'Visualizza dettagli',
      edit: 'Modifica',
      update: 'Aggiorna',
      confirm: 'Conferma',
      delete: 'Elimina',
      modify: 'Modifica',
      accept: 'Accetta',
      reject: 'Rifiuta',
      cancel: 'Annulla',
      close: 'Chiudi',
      year: 'Anno',
      lastName: 'Cognome',
      role: 'Ruolo',
      avatar: 'Avatar',
      invite: 'Invita',
      email: 'Email',
      loading: 'Caricamento...',
      confirmDelete: 'Sei sicuro di volerlo eliminare?',
      checkFormErrors: 'Devi compilare tutti i campi obbligatori',
      genericError: 'Si è verificato un errore, controlla che i dati siano corretti e riprova',
      genericResourceNotFound: 'La risorsa non esiste, non hai i permessi necessari o è stata eliminata',
      error: 'Errore',
      errors: 'Errori',
      other: 'Altro',
      status: 'Stato',
      back: 'Indietro',
      goBack: 'Torna indietro',
      toolNotFound: 'Strumento non trovato',
      permissions: 'Permessi',
      table: 'Tabella',
      chart: 'Grafico',
      months: {
        1: 'Gennaio',
        2: 'Febbraio',
        3: 'Marzo',
        4: 'Aprile',
        5: 'Maggio',
        6: 'Giugno',
        7: 'Luglio',
        8: 'Agosto',
        9: 'Settembre',
        10: 'Ottobre',
        11: 'Novembre',
        12: 'Dicembre',
      },
      date: 'Data',
      value: 'Valore',
      target: 'Obiettivo',
      percentage: 'Percentuale',
      noData: 'Nessun dato disponibile',
      noResults: 'Nessun risultato',
      currency: 'Valuta',
      notes: 'Note',
      type: 'Tipo',
      types: 'Tipi',
      yes: 'Sì',
      no: 'No',
      to: 'a',
      annual: 'Annuale',
      monthly: 'Mensile',
      daily: 'Giornaliero',
      total: 'Totale',
      pending: 'In sospeso',
      createdAt: 'Creato il',
      excel: 'Excel',
      both: 'Entrambi',
      report: 'Report',
      stop: 'Fermare',
      notFound: 'Non trovato',
      actions: 'Azioni',
      auto: 'Automatico',
      input: 'Entrata',
      currencies: {
        currencies: 'Valute',
        USD: 'Dollaro statunitense ($)',
        EUR: 'Euro (€)',
        JPY: 'Yen (¥)',
        AUD: 'Dollaro australiano (A$)',
        CAD: 'Dollaro canadese (C$)',
        GBP: 'Sterlina britannica (£)',
        CNY: 'Yuan cinese (元/¥)',
        HKD: 'Dollaro di Hong Kong (HK$)',
        CHF: 'Franco svizzero (CHF)',
        NZD: 'Dollaro neozelandese (NZ$)',
      },
      currenciesSymbols: {
        USD: '$',
        EUR: '€',
        JPY: '¥',
        AUD: 'A$',
        CAD: 'C$',
        GBP: '£',
        CNY: '元/¥',
        HKD: 'HK$',
        CHF: 'CHF',
        NZD: 'NZ$',
      },
      time: {
        seconds: 'Secondi',
        minutes: 'Minuti',
        hours: 'Ore',
        days: 'Giorni',
        weeks: 'Settimane',
        months: 'Mesi',
        years: 'Anni',
        ago: 'fa %number% %time%',
      },
      language: 'Lingua',
      languages: {
        es: 'Spagnolo',
        en: 'Inglese',
        it: 'Italiano',
      },
    },
  },
};
