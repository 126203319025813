import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import InputSelect from 'components/Inputs/inputSelect';
import InputText from 'components/Inputs/inputText';
import OutputErrors from 'components/Inputs/outputErrors';
import Button from 'components/Buttons/Button';
import Loading from 'components/Loading';
import Alert from 'components/Alert';
import { postShareClassAction, putShareClassAction } from 'routes/Captable/modules/actions';
import { formatDate } from 'utils/functions';
import InputPermission from 'components/Inputs/InputPermission';
import { PERMISSION_SCOPES, PERMISSION_TYPES } from 'constants/permissions';
import { initPermission } from 'utils/functions/initPermissions';
import { AmplitudeApi } from 'utils/amplitude';
import { TOOL_CAP_TABLE } from 'constants/tools';
import PopupFormShareClassLiquidity from './PopupFormShareClassLiquidity';
import PopupFormShareClassParticipation from './PopupFormShareClassParticipation';
import PopupFormShareClassDividend from './PopupFormShareClassDividend';
import PopupFormShareClassConvert from './PopupFormShareClassConvert';
import PopupFormShareClassVote from './PopupFormShareClassVote';
import { fetchCaptableShares } from '../modules/actions';

const defaultShareClass = {
  type: 'common',
  name: '',
  code: '',
  vote: {
    enabled: false,
    value: 0,
  },
  liquidity: {
    enabled: false,
    seniority: null,
    multiple: 1,
  },
  participation: {
    enabled: false,
    cap: 0,
  },
  dividend: {
    enabled: false,
    percentage: 1,
    frequency: '',
    type: '',
    compounding: null,
  },
  convert: {
    enabled: false,
    ratio: 1,
    price: 0,
  },
  notes: '',
  documents: [],
};

const PopupFormShareClass = (props) => {
  const {
    startup,
    captable,
    onClose,
    shareClass,
    putShareClass,
    postShareClass,
    onSubmit,
    literals,
  } = props;

  const [data, setData] = useState(shareClass?.id ? JSON.parse(JSON.stringify(shareClass)) : defaultShareClass);
  const [permission, setPermission] = useState(initPermission(
    shareClass?.id ? shareClass : null,
    PERMISSION_SCOPES.STARTUP,
    startup,
    TOOL_CAP_TABLE,
  ));
  const [savingForm, setSavingForm] = useState(false);
  const [shares, setShares] = useState(null);
  const [errors, setErrors] = useState({});
  const { canEdit } = permission;

  const booleanOptions = [
    { id: true, name: literals.yes },
    { id: false, name: literals.no },
  ];

  const typeOptions = [
    { id: 'common', name: literals.common },
    { id: 'preferred', name: literals.preferred },
  ];

  const getShares = async () => {
    if (shareClass.id) {
      const capShares = await fetchCaptableShares(
        startup.id,
        formatDate(new Date(), { format: 'Y-m-d' }),
        { filters: { shareClass: shareClass.id }, captable },
      );
      setShares(capShares);
    }
  };

  useEffect(() => {
    getShares();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeElement = (atribute, value, property = false) => {
    const aux = { ...data };
    if (property && atribute) {
      if (!aux[property]) {
        aux[property] = {};
      }
      aux[property][atribute] = value;
    } else if (atribute === 'seniority' || atribute === 'multiple') {
      aux.liquidity[atribute] = parseInt(value, 10);
    } else if (atribute === 'cap') {
      aux.participation[atribute] = parseInt(value, 10);
    } else if (atribute === 'percentage' || atribute === 'frequency' || atribute === 'type' || atribute === 'compounding') {
      if (atribute === 'percentage') {
        aux.dividend[atribute] = parseFloat(value);
      } else {
        aux.dividend[atribute] = value;
      }
    } else if (atribute === 'price' || atribute === 'ratio') {
      aux.convert[atribute] = parseInt(value, 10);
    } else if (atribute === 'typeClass') {
      aux.type = value;
    } else {
      aux[atribute] = value;
    }
    setData(aux);
  };

  const changeEnabled = (value, atribute) => {
    const aux = { ...data };
    if (!aux[atribute]) {
      aux[atribute] = {};
    }
    aux[atribute].enabled = value;
    setData(aux);
  };

  const submit = async (event) => {
    event.preventDefault();
    const param = { ...data, permission };
    if (param.type === 'common') {
      param.vote = defaultShareClass.vote;
      param.liquidity = defaultShareClass.liquidity;
      param.participation = defaultShareClass.participation;
      param.dividend = defaultShareClass.dividend;
      param.convert = defaultShareClass.convert;
    }

    setSavingForm(true);
    try {
      setErrors({});
      const response = await ((param.id) ? putShareClass(startup.id, param) : postShareClass(startup.id, param));
      setSavingForm(false);
      if (typeof onSubmit === 'function') {
        onSubmit(response);
      }
      AmplitudeApi.successEvent('startup.captable.summary.shareClass.click.newClass', { captable });
      onClose();
    } catch (exception) {
      AmplitudeApi.errorEvent('startup.captable.summary.shareClass.click.newClass', { captable });
      setErrors(exception);
      setSavingForm(false);
    }
  };

  return (
    <div>
      <Popup title={literals.FormShareClass} onClose={onClose} size='large'>
        {shareClass.id && shares && shares.total > 0 && (
          <Alert type='warning' text={literals.modifyWarning} />
        )}
        {!shareClass.id || shares ? (
          <form onSubmit={submit}>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <InputSelect
                  preText={literals.type}
                  options={typeOptions}
                  value={data.type ? data.type : ''}
                  onChange={value => changeElement('typeClass', value)}
                  isRequired
                  isDisabled={!canEdit}
                />
              </div>
              <div className='col-12 col-md-8'>
                <InputText
                  name='name'
                  preText={literals.name}
                  value={data.name ? data.name : ''}
                  onChange={value => changeElement('name', value)}
                  isRequired
                  isDisabled={!canEdit}
                />
              </div>
              <div className='col-12 col-md-4'>
                <InputText
                  name='code'
                  preText={literals.code}
                  value={data.code ? data.code : ''}
                  placeholder={`${data?.type === 'preferred' ? 'P' : 'C'}-XXX`}
                  onChange={value => changeElement('code', value)}
                  isDisabled={!canEdit}
                />
              </div>
              <div className='col-12 col-md-4'>
                <InputPermission
                  scope={{ type: PERMISSION_SCOPES.STARTUP, id: startup.id }}
                  element={{ type: PERMISSION_TYPES.SHARECLASS, id: data.id }}
                  value={permission}
                  onChange={setPermission}
                />
              </div>
            </div>
            {data.type === 'preferred' && (
              <>
                <div className='row'>
                  <div className='col-md-3'>
                    <InputSelect
                      preText={literals.vote}
                      options={booleanOptions}
                      value={data.vote?.enabled || false}
                      onChange={value => changeEnabled(value, 'vote')}
                      isDisabled={!canEdit}
                    />
                  </div>
                  {data.vote?.enabled && (
                    <PopupFormShareClassVote
                      vote={data.vote}
                      onChange={(attr, value) => changeElement(attr, value, 'vote')}
                      literals={literals}
                      isDisabled={!canEdit}
                    />
                  )}
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <InputSelect
                      preText={literals.participation}
                      options={booleanOptions}
                      value={data.participation?.enabled ? data.participation.enabled : ''}
                      onChange={value => changeEnabled(value, 'participation')}
                      isDisabled={!canEdit}
                    />
                  </div>
                  {data.participation?.enabled && (
                    <PopupFormShareClassParticipation
                      participation={data.participation}
                      onChange={changeElement}
                      literals={literals}
                      isDisabled={!canEdit}
                    />
                  )}
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <InputSelect
                      preText={literals.liquidity}
                      options={booleanOptions}
                      value={data.liquidity?.enabled || false}
                      onChange={value => changeEnabled(value, 'liquidity')}
                      isDisabled={!canEdit}
                    />
                  </div>
                  {data.liquidity?.enabled && (
                    <PopupFormShareClassLiquidity
                      liquidity={data.liquidity}
                      onChange={changeElement}
                      literals={literals}
                      isDisabled={!canEdit}
                    />
                  )}
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <InputSelect
                      preText={literals.dividend}
                      options={booleanOptions}
                      value={data.dividend?.enabled ? data.dividend.enabled : ''}
                      onChange={value => changeEnabled(value, 'dividend')}
                      isDisabled={!canEdit}
                    />
                  </div>
                  {data.dividend?.enabled && (
                    <PopupFormShareClassDividend
                      dividend={data.dividend}
                      onChange={changeElement}
                      literals={literals}
                      isDisabled={!canEdit}
                    />
                  )}
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <InputSelect
                      preText={literals.convert}
                      options={booleanOptions}
                      value={data.convert?.enabled ? data.convert.enabled : ''}
                      onChange={value => changeEnabled(value, 'convert')}
                      isDisabled={!canEdit}
                    />
                  </div>
                  {data.convert?.enabled && (
                    <PopupFormShareClassConvert
                      convert={data.convert}
                      onChange={changeElement}
                      literals={literals}
                      isDisabled={!canEdit}
                    />
                  )}
                </div>
              </>
            )}
            <OutputErrors literals={literals} errors={errors} />
            {canEdit && (
              <div className='buttons'>
                <Button text={literals.save} type='submit' loading={savingForm} />
              </div>
            )}
          </form>
        ) : (
          <Loading hide={false} mode='panel' />
        )}
      </Popup>
    </div>
  );
};

const mapStateToProps = state => ({
  literals: state.i18n.literals.shareClass,
  literalsCommon: state.i18n.literals.common,
  startup: state.global.startup,
});

const mapDispatchToProps = dispatch => ({
  postShareClass: bindActionCreators(postShareClassAction, dispatch),
  putShareClass: bindActionCreators(putShareClassAction, dispatch),
});

PopupFormShareClass.propTypes = {
  startup: PropTypes.object.isRequired,
  captable: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  shareClass: PropTypes.object,
  postShareClass: PropTypes.func.isRequired,
  putShareClass: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  literals: PropTypes.object.isRequired,
};
PopupFormShareClass.defaultProps = {
  shareClass: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupFormShareClass);
